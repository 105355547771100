@use './mixins' as mixin;

.normal-btn-radius:not(.btn-rounded):not(.btn-floating) {
    border-radius: 8px;
}

.square-btn{
    border-radius: 4px;
    container-type: inline-size;

    &::before{
        font-size: 1.8em;
    }
}

.dropdown-menu{
    padding: 4px 0;
    border-radius: 4px;

    li .dropdown-item{
        padding: 6px 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 8px;
        &:hover, &.active{
            color: white;
            font-weight: 500;
        }
        &:last-child, &:first-child{
            border-radius: 0;
        }

        i{
            font-size: 24px;
            color: inherit;
        }
    }
}

.shaded-btn{
    position: relative;
    z-index: 1;

    &.border-success::after{
        @include mixin.shaded-bg-builder($success);
    }
    &.border-warning::after{
        @include mixin.shaded-bg-builder($warning);
    }
    &.border-info::after{
        @include mixin.shaded-bg-builder($info);
    }
    &.border-danger::after{
        @include mixin.shaded-bg-builder($danger);
    }

    &::before{
        content: '';
        z-index: -1;
        position: absolute;
        width: 80%;
        height: 70%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;
    }
}