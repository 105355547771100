
.loader-success {
  border-bottom-color: $success !important;
}

.loader-danger {
  border-bottom-color: $danger !important;
}

.loader-info {
  border-bottom-color: $info !important;
}

.loader-light {
  border-bottom-color: $light !important;
}

.loader-warning {
  border-bottom-color: $warning !important;
}

.loader-dark {
  border-bottom-color: $danger !important;
}
