@font-face {
  font-family: 'seech-icons-fill';
  src: url('../../assets/fonts/seech-icons-fill.ttf?4413ki') format('truetype'),
    url('../../assets/fonts/seech-icons-fill.woff?4413ki') format('woff'),
    url('../../assets/fonts/seech-icons-fill.svg?4413ki#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[data-icon='fill'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'seech-icons-fill' !important;
  speak: never;
  font-style: normal;
  font-weight: 600;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.si-align-x-right-fill:before {
  content: '\e978';
}
.si-align-x-start-fill:before {
  content: '\e979';
}
.si-arrow-top-left-fill:before {
  content: '\e97a';
}
.si-arrow-top-right-fill:before {
  content: '\e97b';
}
.si-bold-fill:before {
  content: '\e97c';
}
.si-content-cut-fill:before {
  content: '\e97d';
}
.si-flex-end-fill:before {
  content: '\e97e';
}
.si-flex-start-fill:before {
  content: '\e97f';
}
.si-italics-fill:before {
  content: '\e980';
}
.si-justify-center-fill:before {
  content: '\e981';
}
.si-list-bullet-fill:before {
  content: '\e982';
}
.si-list-number-fill:before {
  content: '\e983';
}
.si-underline-fill:before {
  content: '\e984';
}
.si-like-fill:before {
  content: '\e977';
}
.si-sparkle-fill:before {
  content: '\e976';
}
.si-padlock-fill:before {
  content: '\e975';
}
.si-money-bag-fill:before {
  content: '\e973';
}
.si-notes-renew-fill:before {
  content: '\e974';
}
.si-paid-fill:before {
  content: '\e970';
}
.si-receipt-fill:before {
  content: '\e971';
}
.si-support-fill:before {
  content: '\e972';
}
.si-chevron-right-fill:before {
  content: '\e96e';
}
.si-folder-open-fill:before {
  content: '\e96f';
}
.si-account-circle-fill:before {
  content: '\e900';
}
.si-add-circle-fill:before {
  content: '\e901';
}
.si-add-event-fill:before {
  content: '\e902';
}
.si-add-location-fill:before {
  content: '\e903';
}
.si-add-photo-fill:before {
  content: '\e904';
}
.si-alt-email-fill:before {
  content: '\e905';
}
.si-arrow-back-fill:before {
  content: '\e906';
}
.si-arrow-diagonal-fill:before {
  content: '\e907';
}
.si-arrow-drop-down-fill:before {
  content: '\e908';
}
.si-arrow-drop-up-fill:before {
  content: '\e909';
}
.si-arrow-forward-fill:before {
  content: '\e90a';
}
.si-autorenew-fill:before {
  content: '\e90b';
}
.si-bid-fill:before {
  content: '\e90c';
}
.si-block-fill:before {
  content: '\e90d';
}
.si-bookmark-fill:before {
  content: '\e90e';
}
.si-briefcase-fill:before {
  content: '\e90f';
}
.si-calendar-fill:before {
  content: '\e910';
}
.si-cancel-fill:before {
  content: '\e911';
}
.si-card-fill:before {
  content: '\e912';
}
.si-chat-fill:before {
  content: '\e913';
}
.si-check-circle-fill:before {
  content: '\e914';
}
.si-checkmark-fill:before {
  content: '\e915';
}
.si-clock-fill:before {
  content: '\e916';
}
.si-close-fill:before {
  content: '\e917';
}
.si-collapse-fill:before {
  content: '\e918';
}
.si-colors-fill:before {
  content: '\e919';
}
.si-comment-fill:before {
  content: '\e91a';
}
.si-contacts-fill:before {
  content: '\e91b';
}
.si-copy-fill:before {
  content: '\e91c';
}
.si-dashboard-fill:before {
  content: '\e91d';
}
.si-delete-fill:before {
  content: '\e91e';
}
.si-dotted-clock-fill:before {
  content: '\e91f';
}
.si-double-checkmark-fill:before {
  content: '\e920';
}
.si-download-fill:before {
  content: '\e921';
}
.si-edit-box-fill:before {
  content: '\e922';
}
.si-edit-fill:before {
  content: '\e923';
}
.si-energy-fill:before {
  content: '\e924';
}
.si-event-check-fill:before {
  content: '\e925';
}
.si-event-fill:before {
  content: '\e926';
}
.si-expand-fill:before {
  content: '\e927';
}
.si-filter-fill:before {
  content: '\e928';
}
.si-folder-fill:before {
  content: '\e929';
}
.si-fullscreen-exit-fill:before {
  content: '\e92a';
}
.si-fullscreen-fill:before {
  content: '\e92b';
}
.si-geotag-fill:before {
  content: '\e92c';
}
.si-group-fill:before {
  content: '\e92d';
}
.si-groups-fill:before {
  content: '\e92e';
}
.si-happy-fill:before {
  content: '\e92f';
}
.si-headset-fill:before {
  content: '\e930';
}
.si-help-fill:before {
  content: '\e931';
}
.si-home-fill:before {
  content: '\e932';
}
.si-info-fill:before {
  content: '\e933';
}
.si-layers-fill:before {
  content: '\e934';
}
.si-layout-fill:before {
  content: '\e935';
}
.si-location-on-fill:before {
  content: '\e936';
}
.si-login-fill:before {
  content: '\e937';
}
.si-logout-fill:before {
  content: '\e938';
}
.si-love-fill:before {
  content: '\e939';
}
.si-mail-fill:before {
  content: '\e93a';
}
.si-map-fill:before {
  content: '\e93b';
}
.si-menu-fill:before {
  content: '\e93c';
}
.si-more-horizontal-fill:before {
  content: '\e93d';
}
.si-more-vertical-fill:before {
  content: '\e93e';
}
.si-next-arrow-fill:before {
  content: '\e93f';
}
.si-note-approve-fill:before {
  content: '\e940';
}
.si-note-delete-fill:before {
  content: '\e941';
}
.si-note-stack-fill:before {
  content: '\e942';
}
.si-notification-fill:before {
  content: '\e943';
}
.si-notifications-unread-fill:before {
  content: '\e944';
}
.si-pause-circle-fill:before {
  content: '\e945';
}
.si-person-add-fill:before {
  content: '\e946';
}
.si-person-fill:before {
  content: '\e947';
}
.si-person-off-fill:before {
  content: '\e948';
}
.si-person-pin-fill:before {
  content: '\e949';
}
.si-phone-fill:before {
  content: '\e94a';
}
.si-photo-library-fill:before {
  content: '\e94b';
}
.si-photo-prints-fill:before {
  content: '\e94c';
}
.si-pin-fill:before {
  content: '\e94d';
}
.si-play-fill:before {
  content: '\e94e';
}
.si-playlist-add-fill:before {
  content: '\e94f';
}
.si-plus-fill:before {
  content: '\e950';
}
.si-prev-arrow-fill:before {
  content: '\e951';
}
.si-priority-fill:before {
  content: '\e952';
}
.si-reply-fill:before {
  content: '\e953';
}
.si-search-fill:before {
  content: '\e954';
}
.si-secure-fill:before {
  content: '\e955';
}
.si-send-fill:before {
  content: '\e956';
}
.si-settings-fill:before {
  content: '\e957';
}
.si-share-fill:before {
  content: '\e958';
}
.si-stacks-fill:before {
  content: '\e959';
}
.si-star-badge-fill:before {
  content: '\e95a';
}
.si-star-fill:before {
  content: '\e95b';
}
.si-toggle-off-fill:before {
  content: '\e95c';
}
.si-trending-fill:before {
  content: '\e95d';
}
.si-tune-fill:before {
  content: '\e95e';
}
.si-upload-file-fill:before {
  content: '\e95f';
}
.si-upload-image-fill:before {
  content: '\e960';
}
.si-verified-fill:before {
  content: '\e961';
}
.si-vert-swap-arrow-fill:before {
  content: '\e962';
}
.si-video-camera-fill:before {
  content: '\e963';
}
.si-visibility-fill:before {
  content: '\e964';
}
.si-visibility-off-fill:before {
  content: '\e965';
}
.si-volume-off-fill:before {
  content: '\e966';
}
.si-volume-on-fill:before {
  content: '\e967';
}
.si-wallet-fill:before {
  content: '\e968';
}
.si-warning-fill:before {
  content: '\e969';
}
.si-work-fill:before {
  content: '\e96a';
}
.si-workspace-fill:before {
  content: '\e96b';
}
.si-zoom-in-fill:before {
  content: '\e96c';
}
.si-zoom-out-fill:before {
  content: '\e96d';
}
