
.indeterminate-success {
  background-color: $success;
}

.indeterminate-danger {
  background-color: $danger;
}

.indeterminate-info {
  background-color: $info;
}

.indeterminate-light {
  background-color: $light;
}

.indeterminate-warning {
  background-color: $warning;
}

.indeterminate-dark {
  background-color: $dark;
}
