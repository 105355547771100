@font-face {
  font-family: 'seech-icons';
  src: url('../../assets/fonts/seech-icons.ttf?4413ki') format('truetype'),
    url('../../assets/fonts/seech-icons.woff?4413ki') format('woff'),
    url('../../assets/fonts/seech-icons.svg?4413ki#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[data-icon='outline'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'seech-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: 600;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.si-add-column-right:before {
  content: "\e9a9";
}
.si-code-off:before {
  content: "\e9aa";
}
.si-socials:before {
  content: "\e9ab";
}
.si-timer:before {
  content: "\e9ac";
}
.si-link-off:before {
  content: "\e9a8";
}
.si-pescription:before {
  content: "\e9a3";
}

.si-music-note-plus:before {
  content: "\e9a4";
}

.si-caption:before {
  content: "\e9a5";
}

.si-emoji-plus:before {
  content: "\e9a6";
}

.si-upload:before {
  content: "\e9a7";
}

.si-redo:before {
  content: "\e9a1";
  color: #aaaeb2;
}

.si-undo:before {
  content: "\e9a2";
}

.si-resize:before {
  content: "\e99d";
}

.si-dots:before {
  content: "\e99e";
}

.si-fonts:before {
  content: "\e99f";
}

.si-shapes:before {
  content: "\e9a0";
}

.si-add-pin:before {
  content: "\e999";
}

.si-approve-check:before {
  content: "\e99a";
  color: #43a047;
}

.si-idea:before {
  content: "\e99b";
  color: #1e88e5;
}

.si-question-mark:before {
  content: "\e99c";
  color: #808080;
}

.si-url:before {
  content: '\e998';
}

.si-remove-circle:before {
  content: '\e997';
}

.si-arrow-back-circle:before {
  content: '\e996';
}

.si-video:before {
  content: '\e994';
}

.si-landscape-line:before {
  content: '\e995';
}

.si-like:before {
  content: '\e993';
}

.si-dashboard-circle:before {
  content: '\e98f';
}

.si-cube:before {
  content: '\e990';
}

.si-hub:before {
  content: '\e991';
}

.si-lens:before {
  content: '\e992';
}

.si-padlock:before {
  content: '\e98e';
}

.si-settings-default:before {
  content: '\e98d';
}

.si-currency-exchange:before {
  content: '\e987';
}

.si-history:before {
  content: '\e988';
}

.si-money-bag:before {
  content: '\e989';
}

.si-notes-renew:before {
  content: '\e98a';
}

.si-price-check:before {
  content: '\e98b';
}

.si-timeline:before {
  content: '\e98c';
}

.si-arrow-downwards:before {
  content: '\e983';
}

.si-paid:before {
  content: '\e984';
}

.si-receipt:before {
  content: '\e985';
}

.si-support:before {
  content: '\e986';
}

.si-chevron-right:before {
  content: '\e981';
}

.si-folder-open:before {
  content: '\e982';
}

.si-arrow-drop-down:before {
  content: '\e980';
}

.si-check-circle:before {
  content: '\e97e';
}

.si-warning:before {
  content: '\e97f';
}

.si-upload-file:before {
  content: '\e979';
}

.si-upload-image:before {
  content: '\e97a';
}

.si-notifications-unread:before {
  content: '\e97b';
}

.si-photo-library:before {
  content: '\e97c';
}

.si-photo-prints:before {
  content: '\e97d';
}

.si-circled-bookmark:before {
  content: '\e973';
}

.si-clipboard:before {
  content: '\e974';
}

.si-handshake:before {
  content: '\e975';
}

.si-inbox:before {
  content: '\e976';
}

.si-messaging:before {
  content: '\e977';
}

.si-portfolio:before {
  content: '\e978';
}

.si-layers:before {
  content: '\e972';
}

.si-card:before {
  content: '\e971';
}

.si-product:before {
  content: '\e96d';
}

.si-professional:before {
  content: '\e96e';
}

.si-wallet:before {
  content: '\e96f';
}

.si-work:before {
  content: '\e970';
}

.si-workspace:before {
  content: '\e96c';
}

.si-dashboard-rounded:before {
  content: '\e96b';
}

.si-playlist-add-fill:before {
  content: '\e96a';
}

.si-mic:before {
  content: '\e969';
}

.si-invitation:before {
  content: '\e968';
}

.si-download:before {
  content: '\e967';
}

.si-pause-circle:before {
  content: '\e964';
}

.si-volume-on:before {
  content: '\e965';
}

.si-volume-off:before {
  content: '\e966';
}

.si-star:before {
  content: '\e963';
}

.si-bid-outline:before {
  content: '\e962';
}

.si-arrow-diagonal:before {
  content: '\e95f';
}

.si-badge:before {
  content: '\e960';
}

.si-verified:before {
  content: '\e961';
}

.si-plus:before {
  content: '\e95e';
}

.si-edit-box:before {
  content: '\e95d';
}

.si-delete:before {
  content: '\e95c';
}

.si-double-checkmark:before {
  content: '\e95b';
}

.si-android-arrow-back:before {
  content: '\e95a';
}

.si-filter-outline:before {
  content: '\e957';
}

.si-location-outline:before {
  content: '\e958';
}

.si-vert-swap-arrow:before {
  content: '\e959';
}

.si-checkmark:before {
  content: '\e953';
}

.si-dotted-clock:before {
  content: '\e954';
}

.si-phone:before {
  content: '\e955';
}

.si-video-camera:before {
  content: '\e956';
}

.si-visibility-off:before {
  content: '\e951';
}

.si-visibility:before {
  content: '\e952';
}

.si-info-outline:before {
  content: '\e950';
}

.si-headset:before {
  content: '\e941';
}

.si-briefcase:before {
  content: '\e94d';
}

.si-folder:before {
  content: '\e94e';
}

.si-stacks:before {
  content: '\e94f';
}

.si-note-approve:before {
  content: '\e94a';
}

.si-note-delete:before {
  content: '\e94b';
}

.si-note-stack:before {
  content: '\e94c';
}

.si-arrow-drop-up:before {
  content: '\e949';
}

.si-bid-fill:before {
  content: '\e942';
}

.si-bookmark-fill:before {
  content: '\e943';
}

.si-energy:before {
  content: '\e944';
}

.si-location-fill:before {
  content: '\e945';
}

.si-more-horizontal-fill:before {
  content: '\e946';
}

.si-more-vertical-fill:before {
  content: '\e947';
}

.si-star-badge-fill:before {
  content: '\e948';
}

.si-mail:before {
  content: '\e940';
}

.si-login:before {
  content: '\e93f';
}

.si-dashboard:before {
  content: '\e93d';
}

.si-help:before {
  content: '\e93e';
}

.si-menu:before {
  content: '\e900';
}

.si-account-circle:before {
  content: '\e901';
}

.si-add-circle:before {
  content: '\e902';
}

.si-add-event:before {
  content: '\e903';
}

.si-add-location:before {
  content: '\e904';
}

.si-add-mail:before {
  content: '\e905';
}

.si-add-photo:before {
  content: '\e906';
}

.si-alt-email:before {
  content: '\e907';
}

.si-arrow-back:before {
  content: '\e908';
}

.si-arrow-forward:before {
  content: '\e909';
}

.si-auto-renew:before {
  content: '\e90a';
}

.si-block:before {
  content: '\e90b';
}

.si-bookmark:before {
  content: '\e90c';
}

.si-boundary:before {
  content: '\e90d';
}

.si-calendar:before {
  content: '\e90e';
}

.si-cancel:before {
  content: '\e90f';
}

.si-chat:before {
  content: '\e910';
}

.si-clock:before {
  content: '\e911';
}

.si-close:before {
  content: '\e912';
}

.si-collapse:before {
  content: '\e913';
}

.si-colors:before {
  content: '\e914';
}

.si-comment:before {
  content: '\e915';
}

.si-contacts:before {
  content: '\e916';
}

.si-copy:before {
  content: '\e917';
}

.si-edit:before {
  content: '\e918';
}

.si-event-check:before {
  content: '\e919';
}

.si-event:before {
  content: '\e91a';
}

.si-expand:before {
  content: '\e91b';
}

.si-fullscreen-exit:before {
  content: '\e91c';
}

.si-fullscreen:before {
  content: '\e91d';
}

.si-geotag:before {
  content: '\e91e';
}

.si-group:before {
  content: '\e91f';
}

.si-groups:before {
  content: '\e920';
}

.si-happy:before {
  content: '\e921';
}

.si-home:before {
  content: '\e922';
}

.si-layout:before {
  content: '\e923';
}

.si-link:before {
  content: '\e924';
}

.si-logout:before {
  content: '\e925';
}

.si-love:before {
  content: '\e926';
}

.si-map:before {
  content: '\e927';
}

.si-next-arrow:before {
  content: '\e928';
}

.si-notification:before {
  content: '\e929';
}

.si-person-add:before {
  content: '\e92a';
}

.si-person-off:before {
  content: '\e92b';
}

.si-person-pin:before {
  content: '\e92c';
}

.si-person:before {
  content: '\e92d';
}

.si-pin:before {
  content: '\e92e';
}

.si-play:before {
  content: '\e92f';
}

.si-prev-arrow:before {
  content: '\e930';
}

.si-priority:before {
  content: '\e931';
}

.si-reply:before {
  content: '\e932';
}

.si-search:before {
  content: '\e933';
}

.si-secured:before {
  content: '\e934';
}

.si-send:before {
  content: '\e935';
}

.si-settings:before {
  content: '\e936';
}

.si-share:before {
  content: '\e937';
}

.si-toggle-off:before {
  content: '\e938';
}

.si-trending:before {
  content: '\e939';
}

.si-tune:before {
  content: '\e93a';
}

.si-zoom-in:before {
  content: '\e93b';
}

.si-zoom-out:before {
  content: '\e93c';
}