//All !important here are required
.money-input-control,
.money-dropdown {
  // border: 1px solid $input-border-color;
  border-width: 1px;
  border-style: solid;
  &:disabled {
    color: $input-disabled-color !important;
  }
}

.currency-search::placeholder,
.money-input-control::placeholder {
  color: $input-placeholder-color !important;
  font-size: 0.85rem;
}

/* Firefox */
.currency-search::-ms-input-placeholder,
.money-input-control::placeholder {
  color: $input-placeholder-color !important;
  font-size: 0.85rem;
}

.border-disabled {
  border-color: $disabled-color;
}

.bg-disabled {
  background-color: rgba(226, 226, 226, 0.3);
}

.color-disabled {
  color: $disabled-color;
}

.money-input-label {
  font-size: 12px;
  font-weight: 500;
  line-height: 145%;
  margin-bottom: 8px;
}
