@import '../spinner';
@import '../money';
@import '../progress-bar';
@import '../side-nav';
@import '../tabs';
@import '../carousel';
@import '../checkbox';
@import '../form';
@import '../button';


.sch-combo-select-dropdown {
  background-color: $white;
}

.sch-combo-select-input {
  &::placeholder {
    color: $dark;
  }
}

.sch-combo-select-dropdown,
.sch-autocomplete-dropdown,
.sch-select-dropdown {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px !important;
  border: 1px solid #CCD4DE !important;
  border-bottom-left-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.sch-autocomplete-option {
  background-color: #ffffff;

  &.selected,
  &:hover {
    color: #ffffff;
  }
}

$sch-input-icon-width: 32px;

.sch-input-icon {
  position: absolute;
  right: 0;
  top: 50%;
  width: $sch-input-icon-width;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-50%);
  transition: all 2s ease-in-out;
}

.sch-input {
  position: relative;
}

.sch-input-control {
  height: 3.063rem;
  width: 100%;
  border-width: 1.5px;
  border-style: solid;
  border-radius: 6px;
  border-color: #CCD4DE;
  padding: 0 0.875rem;
}

.sch-input-field,
.sch-text-area {
  width: 100%;
  padding: 8px 20px 8px 8px;
  text-overflow: ellipsis;
  border-width: 1px;
  border-style: solid;
  transition: all .2s ease-in-out;
  font-size: 14px;
  border-color: #97A2B0;
  border-radius: 6px;
  outline: none !important;
  background-color: transparent;

  &::placeholder {
    color: $input-placeholder-color;
  }

  &:focus,
  .has-value {
    border-color: $primary;
    border-color: $input-border-color;
  }

  &.has-icon {
    &.align-icon-start {
      padding-left: $sch-input-icon-width;

      &+.sch-input-icon {
        left: 0
      }
    }

    &:not(.align-icon-start) {
      padding-right: $sch-input-icon-width;
    }
  }

  &[disabled],
  &[disabled='true'],
  &[ng-reflect-disabled='true'] {
    border-color: #b9b9b9;
    background-color: #ebebeb4d;
    color: #b9b9b9;

    &::placeholder {
      color: #b9b9b9 !important;
    }
  }

  &.ng-invalid.ng-touched,
  .ng-invalid.ng-touched::placeholder {
    border-color: $danger;
    color: $danger;
  }

  &.modern,
  &.hybrid {
    background-color: #0091ea05;
    color: #495057;
    border-color: transparent;
    border-bottom-color: #ced4da;

    &::placeholder {
      color: #495057;
    }

    &:focus,
    .has-value {
      border-radius: 0.25em;
    }

    &:focus+.sch-input-label,
    &.has-placeholder+.sch-input-label,
    &.has-value+.sch-input-label {
      top: -3px;
      left: 1em;
      font-weight: 300;
      padding: 2px 4px;
      border-radius: 0.25em;
    }
  }

  &.hybrid {
    border-color: #ced4da;
  }
}

.sch-input-label {
  font-size: 12px;
  font-weight: 500;
  line-height: 145%;
  margin-bottom: 8px;

  &.modern,
  &.hybrid {
    position: absolute;
    top: 50%;
    left: 1em;
    transform: translateY(-50%);
    transition: all .2s ease-in-out;
    margin-bottom: 0;
    border: 0.5px solid transparent;
  }
}

.adaptive-textarea.sch-text-area:focus-within {
  border-color: $primary !important;
}