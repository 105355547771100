
.sch-tabs-item:hover {
    background-color: $secondary;
}

.sch-tabs-item.active {
    border-bottom: 2px solid $primary;
    background-color: $secondary;
}

.sch-tabs-title {
    color: $dark; 
}

.sch-tabs-title.active {
    color: $primary;
}

.sch-tab-content {
    border: 1px solid $gray-300 !important;
}

.sch-tab-count:active {
    color: white;
}