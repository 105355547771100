
@function btn-shaded-gradient($color) {
    $gradient: repeating-linear-gradient(135deg, $color 0 1px, transparent 2px 7px);
    @return $gradient;
}

@mixin shaded-bg-builder($color) {
    content: '';
    position: absolute; 
    top: 0;
    left: 0; 
    width: 100%;
    height: 100%;
    z-index: -2;
    opacity: 0.6;
    background: btn-shaded-gradient($color);
    border-radius: inherit;
}