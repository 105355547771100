
.carousel-control-prev {
    .carousel-control-prev-icon {
      box-sizing: border-box;
      position: relative;
      transform: scale(var(--ggs, 1));
      border: 2px solid transparent;
      background: rgba(0, 145, 234, 0.8);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      padding: 1.125rem;
  
      &::before {
        content: none !important;
      }
  
      &::after {
        content: '';
        display: block;
        box-sizing: border-box;
        position: absolute;
        width: 1rem;
        height: 1rem;
        border-bottom: 2px solid;
        border-left: 2px solid;
        transform: rotate(45deg);
        margin-left: 0.5rem;
      }
    }
  }
  
  .carousel-control-next {
    .carousel-control-next-icon {
      box-sizing: border-box;
      position: relative;
      display: block;
      transform: scale(var(--ggs, 1));
      border: 2px solid transparent;
      background: rgba(0, 145, 234, 0.8);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      padding: 1.125rem;
  
      &::before {
        content: none !important;
      }
  
      &::after {
        content: '';
        display: block;
        box-sizing: border-box;
        position: absolute;
        width: 1rem;
        height: 1rem;
        border-bottom: 2px solid;
        border-right: 2px solid;
        transform: rotate(-45deg);
        margin-right: 0.5rem;
      }
    }
  }