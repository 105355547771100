// Variables

// Color system

// scss-docs-start gray-color-variables
$white: #fff !default;
$gray-50: #fbfbfb !default;
$gray-100: #f5f5f5 !default;
$gray-200: #eeeeee !default;
$gray-300: #e0e0e0 !default;
$gray-400: #bdbdbd !default;
$gray-500: #9e9e9e !default;
$gray-600: #757575 !default;
$gray-700: #616161 !default;
$gray-800: #4f4f4f !default;
$gray-900: #262626 !default;
$black: #000 !default;
// scss-docs-end gray-color-variables

// scss-docs-start gray-colors-map
$grays: (
  '50': $gray-50,
  '100': $gray-100,
  '200': $gray-200,
  '300': $gray-300,
  '400': $gray-400,
  '500': $gray-500,
  '600': $gray-600,
  '700': $gray-700,
  '800': $gray-800,
  '900': $gray-900,
) !default;
// scss-docs-end gray-colors-map

// scss-docs-start material-design-color-system-variables
$red-50: #ffebee !default;
$red-100: #ffcdd2 !default;
$red-200: #ef9a9a !default;
$red-300: #e57373 !default;
$red-400: #ef5350 !default;
$red-500: #f44336 !default;
$red-600: #e53935 !default;
$red-700: #d32f2f !default;
$red-800: #c62828 !default;
$red-900: #b71c1c !default;
$red-accent-100: #ff8a80 !default;
$red-accent-200: #ff5252 !default;
$red-accent-400: #ff1744 !default;
$red-accent-700: #d50000 !default;

$pink-50: #fce4ec !default;
$pink-100: #f8bbd0 !default;
$pink-200: #f48fb1 !default;
$pink-300: #f06292 !default;
$pink-400: #ec407a !default;
$pink-500: #e91e63 !default;
$pink-600: #d81b60 !default;
$pink-700: #c2185b !default;
$pink-800: #ad1457 !default;
$pink-900: #880e4f !default;
$pink-accent-100: #ff80ab !default;
$pink-accent-200: #ff4081 !default;
$pink-accent-400: #f50057 !default;
$pink-accent-700: #c51162 !default;

$purple-50: #f3e5f5 !default;
$purple-100: #e1bee7 !default;
$purple-200: #ce93d8 !default;
$purple-300: #ba68c8 !default;
$purple-400: #ab47bc !default;
$purple-500: #9c27b0 !default;
$purple-600: #8e24aa !default;
$purple-700: #7b1fa2 !default;
$purple-800: #6a1b9a !default;
$purple-900: #4a148c !default;
$purple-accent-100: #ea80fc !default;
$purple-accent-200: #e040fb !default;
$purple-accent-400: #d500f9 !default;
$purple-accent-700: #aa00ff !default;

$deep-purple-50: #ede7f6 !default;
$deep-purple-100: #d1c4e9 !default;
$deep-purple-200: #b39ddb !default;
$deep-purple-300: #9575cd !default;
$deep-purple-400: #7e57c2 !default;
$deep-purple-500: #673ab7 !default;
$deep-purple-600: #5e35b1 !default;
$deep-purple-700: #512da8 !default;
$deep-purple-800: #4527a0 !default;
$deep-purple-900: #311b92 !default;
$deep-purple-accent-100: #b388ff !default;
$deep-purple-accent-200: #7c4dff !default;
$deep-purple-accent-400: #651fff !default;
$deep-purple-accent-700: #6200ea !default;

$indigo-50: #e8eaf6 !default;
$indigo-100: #c5cae9 !default;
$indigo-200: #9fa8da !default;
$indigo-300: #7986cb !default;
$indigo-400: #5c6bc0 !default;
$indigo-500: #3f51b5 !default;
$indigo-600: #3949ab !default;
$indigo-700: #303f9f !default;
$indigo-800: #283593 !default;
$indigo-900: #1a237e !default;
$indigo-accent-100: #8c9eff !default;
$indigo-accent-200: #536dfe !default;
$indigo-accent-400: #3d5afe !default;
$indigo-accent-700: #304ffe !default;

$blue-50: #e3f2fd !default;
$blue-100: #bbdefb !default;
$blue-200: #90caf9 !default;
$blue-300: #64b5f6 !default;
$blue-400: #42a5f5 !default;
$blue-500: #2196f3 !default;
$blue-600: #1e88e5 !default;
$blue-700: #1976d2 !default;
$blue-800: #1565c0 !default;
$blue-900: #0d47a1 !default;
$blue-accent-100: #82b1ff !default;
$blue-accent-200: #448aff !default;
$blue-accent-400: #2979ff !default;
$blue-accent-700: #2962ff !default;

$light-blue-50: #e1f5fe !default;
$light-blue-100: #b3e5fc !default;
$light-blue-200: #81d4fa !default;
$light-blue-300: #4fc3f7 !default;
$light-blue-400: #29b6f6 !default;
$light-blue-500: #03a9f4 !default;
$light-blue-600: #039be5 !default;
$light-blue-700: #0288d1 !default;
$light-blue-800: #0277bd !default;
$light-blue-900: #01579b !default;
$light-blue-accent-100: #80d8ff !default;
$light-blue-accent-200: #40c4ff !default;
$light-blue-accent-400: #00b0ff !default;
$light-blue-accent-700: #0091ea !default;

$cyan-50: #e0f7fa !default;
$cyan-100: #b2ebf2 !default;
$cyan-200: #80deea !default;
$cyan-300: #4dd0e1 !default;
$cyan-400: #26c6da !default;
$cyan-500: #00bcd4 !default;
$cyan-600: #00acc1 !default;
$cyan-700: #0097a7 !default;
$cyan-800: #00838f !default;
$cyan-900: #006064 !default;
$cyan-accent-100: #84ffff !default;
$cyan-accent-200: #18ffff !default;
$cyan-accent-400: #00e5ff !default;
$cyan-accent-700: #00b8d4 !default;

$teal-50: #e0f2f1 !default;
$teal-100: #b2dfdb !default;
$teal-200: #80cbc4 !default;
$teal-300: #4db6ac !default;
$teal-400: #26a69a !default;
$teal-500: #009688 !default;
$teal-600: #00897b !default;
$teal-700: #00796b !default;
$teal-800: #00695c !default;
$teal-900: #004d40 !default;
$teal-accent-100: #a7ffeb !default;
$teal-accent-200: #64ffda !default;
$teal-accent-400: #1de9b6 !default;
$teal-accent-700: #00bfa5 !default;

$green-50: #e8f5e9 !default;
$green-100: #c8e6c9 !default;
$green-200: #a5d6a7 !default;
$green-300: #81c784 !default;
$green-400: #66bb6a !default;
$green-500: #4caf50 !default;
$green-600: #43a047 !default;
$green-700: #388e3c !default;
$green-800: #2e7d32 !default;
$green-900: #1b5e20 !default;
$green-accent-100: #b9f6ca !default;
$green-accent-200: #69f0ae !default;
$green-accent-400: #00e676 !default;
$green-accent-700: #00c853 !default;

$light-green-50: #f1f8e9 !default;
$light-green-100: #dcedc8 !default;
$light-green-200: #c5e1a5 !default;
$light-green-300: #aed581 !default;
$light-green-400: #9ccc65 !default;
$light-green-500: #8bc34a !default;
$light-green-600: #7cb342 !default;
$light-green-700: #689f38 !default;
$light-green-800: #558b2f !default;
$light-green-900: #33691e !default;
$light-green-accent-100: #ccff90 !default;
$light-green-accent-200: #b2ff59 !default;
$light-green-accent-400: #76ff03 !default;
$light-green-accent-700: #64dd17 !default;

$lime-50: #f9fbe7 !default;
$lime-100: #f0f4c3 !default;
$lime-200: #e6ee9c !default;
$lime-300: #dce775 !default;
$lime-400: #d4e157 !default;
$lime-500: #cddc39 !default;
$lime-600: #c0ca33 !default;
$lime-700: #afb42b !default;
$lime-800: #9e9d24 !default;
$lime-900: #827717 !default;
$lime-accent-100: #f4ff81 !default;
$lime-accent-200: #eeff41 !default;
$lime-accent-400: #c6ff00 !default;
$lime-accent-700: #aeea00 !default;

$yellow-50: #fffde7 !default;
$yellow-100: #fff9c4 !default;
$yellow-200: #fff59d !default;
$yellow-300: #fff176 !default;
$yellow-400: #ffee58 !default;
$yellow-500: #ffeb3b !default;
$yellow-600: #fdd835 !default;
$yellow-700: #fbc02d !default;
$yellow-800: #f9a825 !default;
$yellow-900: #f57f17 !default;
$yellow-accent-100: #ffff8d !default;
$yellow-accent-200: #ffff00 !default;
$yellow-accent-400: #ffea00 !default;
$yellow-accent-700: #ffd600 !default;

$amber-50: #fff8e1 !default;
$amber-100: #ffecb3 !default;
$amber-200: #ffe082 !default;
$amber-300: #ffd54f !default;
$amber-400: #ffca28 !default;
$amber-500: #ffc107 !default;
$amber-600: #ffb300 !default;
$amber-700: #ffa000 !default;
$amber-800: #ff8f00 !default;
$amber-900: #ff6f00 !default;
$amber-accent-100: #ffe57f !default;
$amber-accent-200: #ffd740 !default;
$amber-accent-400: #ffc400 !default;
$amber-accent-700: #ffab00 !default;

$orange-50: #fff3e0 !default;
$orange-100: #ffe0b2 !default;
$orange-200: #ffcc80 !default;
$orange-300: #ffb74d !default;
$orange-400: #ffa726 !default;
$orange-500: #ff9800 !default;
$orange-600: #fb8c00 !default;
$orange-700: #f57c00 !default;
$orange-800: #ef6c00 !default;
$orange-900: #e65100 !default;
$orange-accent-100: #ffd180 !default;
$orange-accent-200: #ffab40 !default;
$orange-accent-400: #ff9100 !default;
$orange-accent-700: #ff6d00 !default;

$deep-orange-50: #fbe9e7 !default;
$deep-orange-100: #ffccbc !default;
$deep-orange-200: #ffab91 !default;
$deep-orange-300: #ff8a65 !default;
$deep-orange-400: #ff7043 !default;
$deep-orange-500: #ff5722 !default;
$deep-orange-600: #f4511e !default;
$deep-orange-700: #e64a19 !default;
$deep-orange-800: #d84315 !default;
$deep-orange-900: #bf360c !default;
$deep-orange-accent-100: #ff9e80 !default;
$deep-orange-accent-200: #ff6e40 !default;
$deep-orange-accent-400: #ff3d00 !default;
$deep-orange-accent-700: #dd2c00 !default;

$brown-50: #efebe9 !default;
$brown-100: #d7ccc8 !default;
$brown-200: #bcaaa4 !default;
$brown-300: #a1887f !default;
$brown-400: #8d6e63 !default;
$brown-500: #795548 !default;
$brown-600: #6d4c41 !default;
$brown-700: #5d4037 !default;
$brown-800: #4e342e !default;
$brown-900: #3e2723 !default;

$blue-gray-50: #eceff1 !default;
$blue-gray-100: #cfd8dc !default;
$blue-gray-200: #b0bec5 !default;
$blue-gray-300: #90a4ae !default;
$blue-gray-400: #78909c !default;
$blue-gray-500: #607d8b !default;
$blue-gray-600: #546e7a !default;
$blue-gray-700: #455a64 !default;
$blue-gray-800: #37474f !default;
$blue-gray-900: #263238 !default;
// scss-docs-end material-design-color-system-variables

// scss-docs-start material-design-color-system-map
$reds: (
  'red-50': $red-50,
  'red-100': $red-100,
  'red-200': $red-200,
  'red-300': $red-300,
  'red-400': $red-400,
  'red-500': $red-500,
  'red-600': $red-600,
  'red-700': $red-700,
  'red-800': $red-800,
  'red-900': $red-900,
  'red-accent-100': $red-accent-100,
  'red-accent-200': $red-accent-200,
  'red-accent-400': $red-accent-400,
  'red-accent-700': $red-accent-700,
) !default;

$pinks: (
  'pink-50': $pink-50,
  'pink-100': $pink-100,
  'pink-200': $pink-200,
  'pink-300': $pink-300,
  'pink-400': $pink-400,
  'pink-500': $pink-500,
  'pink-600': $pink-600,
  'pink-700': $pink-700,
  'pink-800': $pink-800,
  'pink-900': $pink-900,
  'pink-accent-100': $pink-accent-100,
  'pink-accent-200': $pink-accent-200,
  'pink-accent-400': $pink-accent-400,
  'pink-accent-700': $pink-accent-700,
) !default;

$purples: (
  'purple-50': $purple-50,
  'purple-100': $purple-100,
  'purple-200': $purple-200,
  'purple-300': $purple-300,
  'purple-400': $purple-400,
  'purple-500': $purple-500,
  'purple-600': $purple-600,
  'purple-700': $purple-700,
  'purple-800': $purple-800,
  'purple-900': $purple-900,
  'purple-accent-100': $purple-accent-100,
  'purple-accent-200': $purple-accent-200,
  'purple-accent-400': $purple-accent-400,
  'purple-accent-700': $purple-accent-700,
) !default;

$deep-purples: (
  'deep-purple-50': $deep-purple-50,
  'deep-purple-100': $deep-purple-100,
  'deep-purple-200': $deep-purple-200,
  'deep-purple-300': $deep-purple-300,
  'deep-purple-400': $deep-purple-400,
  'deep-purple-500': $deep-purple-500,
  'deep-purple-600': $deep-purple-600,
  'deep-purple-700': $deep-purple-700,
  'deep-purple-800': $deep-purple-800,
  'deep-purple-900': $deep-purple-900,
  'deep-purple-accent-100': $deep-purple-accent-100,
  'deep-purple-accent-200': $deep-purple-accent-200,
  'deep-purple-accent-400': $deep-purple-accent-400,
  'deep-purple-accent-700': $deep-purple-accent-700,
) !default;

$indigos: (
  'indigo-50': $indigo-50,
  'indigo-100': $indigo-100,
  'indigo-200': $indigo-200,
  'indigo-300': $indigo-300,
  'indigo-400': $indigo-400,
  'indigo-500': $indigo-500,
  'indigo-600': $indigo-600,
  'indigo-700': $indigo-700,
  'indigo-800': $indigo-800,
  'indigo-900': $indigo-900,
  'indigo-accent-100': $indigo-accent-100,
  'indigo-accent-200': $indigo-accent-200,
  'indigo-accent-400': $indigo-accent-400,
  'indigo-accent-700': $indigo-accent-700,
) !default;

$blues: (
  'blue-50': $blue-50,
  'blue-100': $blue-100,
  'blue-200': $blue-200,
  'blue-300': $blue-300,
  'blue-400': $blue-400,
  'blue-500': $blue-500,
  'blue-600': $blue-600,
  'blue-700': $blue-700,
  'blue-800': $blue-800,
  'blue-900': $blue-900,
  'blue-accent-100': $blue-accent-100,
  'blue-accent-200': $blue-accent-200,
  'blue-accent-400': $blue-accent-400,
  'blue-accent-700': $blue-accent-700,
) !default;

$light-blues: (
  'light-blue-50': $light-blue-50,
  'light-blue-100': $light-blue-100,
  'light-blue-200': $light-blue-200,
  'light-blue-300': $light-blue-300,
  'light-blue-400': $light-blue-400,
  'light-blue-500': $light-blue-500,
  'light-blue-600': $light-blue-600,
  'light-blue-700': $light-blue-700,
  'light-blue-800': $light-blue-800,
  'light-blue-900': $light-blue-900,
  'light-blue-accent-100': $light-blue-accent-100,
  'light-blue-accent-200': $light-blue-accent-200,
  'light-blue-accent-400': $light-blue-accent-400,
  'light-blue-accent-700': $light-blue-accent-700,
) !default;

$cyans: (
  'cyan-50': $cyan-50,
  'cyan-100': $cyan-100,
  'cyan-200': $cyan-200,
  'cyan-300': $cyan-300,
  'cyan-400': $cyan-400,
  'cyan-500': $cyan-500,
  'cyan-600': $cyan-600,
  'cyan-700': $cyan-700,
  'cyan-800': $cyan-800,
  'cyan-900': $cyan-900,
  'cyan-accent-100': $cyan-accent-100,
  'cyan-accent-200': $cyan-accent-200,
  'cyan-accent-400': $cyan-accent-400,
  'cyan-accent-700': $cyan-accent-700,
) !default;

$teals: (
  'teal-50': $teal-50,
  'teal-100': $teal-100,
  'teal-200': $teal-200,
  'teal-300': $teal-300,
  'teal-400': $teal-400,
  'teal-500': $teal-500,
  'teal-600': $teal-600,
  'teal-700': $teal-700,
  'teal-800': $teal-800,
  'teal-900': $teal-900,
  'teal-accent-100': $teal-accent-100,
  'teal-accent-200': $teal-accent-200,
  'teal-accent-400': $teal-accent-400,
  'teal-accent-700': $teal-accent-700,
) !default;

$greens: (
  'green-50': $green-50,
  'green-100': $green-100,
  'green-200': $green-200,
  'green-300': $green-300,
  'green-400': $green-400,
  'green-500': $green-500,
  'green-600': $green-600,
  'green-700': $green-700,
  'green-800': $green-800,
  'green-900': $green-900,
  'green-accent-100': $green-accent-100,
  'green-accent-200': $green-accent-200,
  'green-accent-400': $green-accent-400,
  'green-accent-700': $green-accent-700,
) !default;

$light-greens: (
  'light-green-50': $light-green-50,
  'light-green-100': $light-green-100,
  'light-green-200': $light-green-200,
  'light-green-300': $light-green-300,
  'light-green-400': $light-green-400,
  'light-green-500': $light-green-500,
  'light-green-600': $light-green-600,
  'light-green-700': $light-green-700,
  'light-green-800': $light-green-800,
  'light-green-900': $light-green-900,
  'light-green-accent-100': $light-green-accent-100,
  'light-green-accent-200': $light-green-accent-200,
  'light-green-accent-400': $light-green-accent-400,
  'light-green-accent-700': $light-green-accent-700,
) !default;

$limes: (
  'lime-50': $lime-50,
  'lime-100': $lime-100,
  'lime-200': $lime-200,
  'lime-300': $lime-300,
  'lime-400': $lime-400,
  'lime-500': $lime-500,
  'lime-600': $lime-600,
  'lime-700': $lime-700,
  'lime-800': $lime-800,
  'lime-900': $lime-900,
  'lime-accent-100': $lime-accent-100,
  'lime-accent-200': $lime-accent-200,
  'lime-accent-400': $lime-accent-400,
  'lime-accent-700': $lime-accent-700,
) !default;

$yellows: (
  'yellow-50': $yellow-50,
  'yellow-100': $yellow-100,
  'yellow-200': $yellow-200,
  'yellow-300': $yellow-300,
  'yellow-400': $yellow-400,
  'yellow-500': $yellow-500,
  'yellow-600': $yellow-600,
  'yellow-700': $yellow-700,
  'yellow-800': $yellow-800,
  'yellow-900': $yellow-900,
  'yellow-accent-100': $yellow-accent-100,
  'yellow-accent-200': $yellow-accent-200,
  'yellow-accent-400': $yellow-accent-400,
  'yellow-accent-700': $yellow-accent-700,
) !default;

$ambers: (
  'amber-50': $amber-50,
  'amber-100': $amber-100,
  'amber-200': $amber-200,
  'amber-300': $amber-300,
  'amber-400': $amber-400,
  'amber-500': $amber-500,
  'amber-600': $amber-600,
  'amber-700': $amber-700,
  'amber-800': $amber-800,
  'amber-900': $amber-900,
  'amber-accent-100': $amber-accent-100,
  'amber-accent-200': $amber-accent-200,
  'amber-accent-400': $amber-accent-400,
  'amber-accent-700': $amber-accent-700,
) !default;

$oranges: (
  'orange-50': $orange-50,
  'orange-100': $orange-100,
  'orange-200': $orange-200,
  'orange-300': $orange-300,
  'orange-400': $orange-400,
  'orange-500': $orange-500,
  'orange-600': $orange-600,
  'orange-700': $orange-700,
  'orange-800': $orange-800,
  'orange-900': $orange-900,
  'orange-accent-100': $orange-accent-100,
  'orange-accent-200': $orange-accent-200,
  'orange-accent-400': $orange-accent-400,
  'orange-accent-700': $orange-accent-700,
) !default;

$deep-oranges: (
  'deep-orange-50': $deep-orange-50,
  'deep-orange-100': $deep-orange-100,
  'deep-orange-200': $deep-orange-200,
  'deep-orange-300': $deep-orange-300,
  'deep-orange-400': $deep-orange-400,
  'deep-orange-500': $deep-orange-500,
  'deep-orange-600': $deep-orange-600,
  'deep-orange-700': $deep-orange-700,
  'deep-orange-800': $deep-orange-800,
  'deep-orange-900': $deep-orange-900,
  'deep-orange-accent-100': $deep-orange-accent-100,
  'deep-orange-accent-200': $deep-orange-accent-200,
  'deep-orange-accent-400': $deep-orange-accent-400,
  'deep-orange-accent-700': $deep-orange-accent-700,
) !default;

$browns: (
  'brown-50': $brown-50,
  'brown-100': $brown-100,
  'brown-200': $brown-200,
  'brown-300': $brown-300,
  'brown-400': $brown-400,
  'brown-500': $brown-500,
  'brown-600': $brown-600,
  'brown-700': $brown-700,
  'brown-800': $brown-800,
  'brown-900': $brown-900,
) !default;

$blue-grays: (
  'blue-gray-50': $blue-gray-50,
  'blue-gray-100': $blue-gray-100,
  'blue-gray-200': $blue-gray-200,
  'blue-gray-300': $blue-gray-300,
  'blue-gray-400': $blue-gray-400,
  'blue-gray-500': $blue-gray-500,
  'blue-gray-600': $blue-gray-600,
  'blue-gray-700': $blue-gray-700,
  'blue-gray-800': $blue-gray-800,
  'blue-gray-900': $blue-gray-900,
) !default;
// scss-docs-end material-design-color-system-map

// scss-docs-start color-variables
$red: $red-500 !default;
$pink: $pink-500 !default;
$purple: purple-500 !default;
$indigo: $indigo-500 !default;
$blue: $blue-500 !default;
$cyan: $cyan-500 !default;
$teal: $teal-500 !default;
$green: $green-500 !default;
$yellow: $yellow-500 !default;
$orange: $orange-500 !default;
// scss-docs-end color-variables

// scss-docs-start colors-map
$colors: (
  'red': $red,
  'pink': $pink,
  'purple': $purple,
  'indigo': $indigo,
  'blue': $blue,
  'cyan': $cyan,
  'teal': $teal,
  'green': $green,
  'yellow': $yellow,
  'orange': $orange,
  'white': $white,
  'black': $black,
  'gray': $gray-600,
  'gray-dark': $gray-800,
) !default;
// scss-docs-end colors-map

// scss-docs-start theme-color-variables
$primary: #3b71ca !default;
$secondary: #9fa6b2 !default;
$success: #14a44d !default;
$danger: #dc4c64 !default;
$warning: #e4a11b !default;
$info: #54b4d3 !default;
$light: #fbfbfb !default;
$dark: #332d2d !default;
// scss-docs-end theme-color-variables
