$primary: #3b71ca;
$secondary: #9fa6b2;
$success: #14a44d;
$danger: #dc4c64;
$warning: #e4a11b;
$info: #54b4d3;
$light: #fbfbfb;
$dark: #332d2d;

$primary-font: 'Nunito Sans', sans-serif;

$seech-layout-width: 12.5rem;
$seech-layout-minimize-width: 3.5rem;
$seech-layout-item-space: 0;
$seech-layout-item-icon-right: 1rem;
$seech-layout-item-icon-left: 0;
$seech-layout-item-selected-overlay: $primary;
$seech-layout-bottom-nav-height: 0;
$seech-layout-header-height: 4rem;
