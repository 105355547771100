

html {
    --mdb-body-font-family: $primary-font;
  //*Mobile phones
  //1rem = 13.6px on large display
  font-size: 85%;
  //*Tablet
  //1rem = 15.2px on large display
  @media (min-width: 768px) {
    font-size: 95%;
  }
  //*Large display
  //1rem = 16px on large display
  @media (min-width: 992px) {
    font-size: 100%;
  }
  //*Large display
  //1rem = 16.8px on large display
  @media (min-width: 1400px) {
    font-size: 105%;
  }
  //*4K Display
  //1rem = 22.4px on 4k display
  @media (min-width: 2560px) {
    font-size: 140%;
  }
}

body,
button {
  font-family: $primary-font, sans-serif;
  font-weight: 400;
}
