/* You can add global styles to this file, and also import other style files */
@import 'seech/core';
@import './scss/overrides';
@import 'seech/theme';
@import './theme';
@import 'font-icon';
@import 'font-icon-fill';

.nav-link-icon {
  font-size: 1rem;
}

.nav-link-label {
  font-size: 0.875rem;
  font-weight: 400;
}

.toggle {
  fill: $light;
}

.demo-container {
  width: 100%;
  height: 400px;
  border-width: 1px;
  border-style: solid;
  position: relative !important;
  border-radius: 5px;
}

.layout-header {
  color: $primary;
  padding-top: 5px;
  padding: 0.5rem;
  border-bottom: 0.063rem solid $primary
}

.seech-sidenav-background {
  background-color: #fff !important;
}

/* Scrollbar styling */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 2px;
  /* Initially set to 0 to hide it */
  background-color: #f5f5f5;
  transition: width 0.3s ease-in-out;
}

::-webkit-scrollbar-thumb {
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #0ae;
  background-image: -webkit-gradient(linear,
      0 0,
      0 100%,
      color-stop(0.5, rgba(255, 255, 255, 0.2)),
      color-stop(0.5, transparent),
      to(transparent));
}

body,
controls-container {
  color: black;
  font-weight: 500;
}

.sch-accordion-item-header {
  font-weight: 600;
  font-size: 20px;
}

div.accordion-body {
  padding: 0.7rem;
}

.controls-box {
  //  padding: 20px 0;
  padding: 0px !important;
  border-radius: 10px;
  height: 100%;
}

.controls-content {
  margin: 1rem 3rem !important;
}


.money-drop-containers,
.contols-drop-containers {
  width: 49.5% !important;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  // flex: 1;

  & .cdc-title {
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
    border-bottom: 1px solid #e0e0e0;
    padding: 15px;
    z-index: 5;
  }

  & .value-events {
    text-align: left;
  }
}

.money-drop-containers {
  width: 100% !important;
}

.title-box {
  font-size: 30px;
  font-weight: 700;
  text-transform: capitalize;
}

.sub-title-box {
  font-size: 16px;
  font-weight: 500;
  text-transform: none;
}

/* Add this CSS to your styles */
.accordion-content-container {
  padding: 15px;
  min-height: 200px !important;
  overflow-y: visible;
  transition: all 0.3s ease-in-out;
  z-index: 0;
  word-break: break-all;
}

.accordion-body {
  padding: 1.5rem;
}

.accordion-button {
  padding: 1.15rem;
}

.expand-height {
  // height: auto !important;
  height: 200px !important;
  width: 100%;
}

@media only screen and (min-width: 767px) {
  div.accordion-body {
    padding: 1.5rem !important;
  }
}

@media only screen and (max-width: 1024px) {
  .contols-drop-containers {
    width: 100% !important;
    margin: 8px 0;
  }

  .expand-height {
    height: 200px !important;
    width: 100%;
  }

  .controls-box {
    border-radius: 10px;
    flex-direction: column !important;
  }

  .controls-content {
    padding: 5px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin: 5px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.sidenav-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 8 !important;
}

.sidenav {
  z-index: 10 !important;
}

.subtitle {
  font-size: 1.5rem;
}

.demo-layout-sidenav {
  box-shadow: 1.5px 4px 4px 1.5px rgba(0, 0, 0, 0.18);
}

// Visualization Styles
/* I have the option to add visualization styles at the global level or at each
component level as they are not responding to the styles in their parent component irrespective
of the encapsulation type that I use for the components */
.chart-wrapper {
  display: flex;
  justify-content: center;
}

.table-wrapper,
.chart-wrapper {
  border-radius: 0.5rem;
  border: 0.063rem solid #e0e0e0;
  padding: 1rem;
}

.table-img {
  width: 2.813rem;
  height: 2.813rem;
}

.theme-toggle {
  .si-colors {
    font-size: 1rem !important;
  }
}

//TODO: caters for demo, move to permanent site when Bello restructuring PR goes in
.plyr__controls>.plyr__control:first-child .plyr__tooltip {
  left: 0 !important;
  right: auto !important;
}

.combo-dropdown-toggle::after {
  margin: 0px -0.375rem -0.313rem -0.438rem !important;
  font-size: 1.438rem;
}

.combo-dropdown-toggle::before {
  content: '|';
  font-size: 0.813rem;
  font-style: normal;
  margin-right: 0.75rem;
}

.lightbox-gallery-right-tools,
.lightbox-gallery-arrow-left,
.lightbox-gallery-arrow-right {
  button:before {
    content: none;
  }

  button {
    font-family: 'seech-icons' !important;
    speak: never;
    font-style: normal;
    font-weight: 600;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-weight: normal;
    font-style: normal;
    font-display: block;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  button[aria-label='Previous'] {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs, 1));
    width: 22px;
    height: 22px;

    &::after,
    &::before {
      content: '';
      display: block;
      box-sizing: border-box;
      position: absolute;
      left: 3px;
    }

    &::after {
      width: 8px;
      height: 8px;
      border-bottom: 2px solid;
      border-left: 2px solid;
      transform: rotate(45deg);
      bottom: 7px;
    }

    &::before {
      width: 16px;
      height: 2px;
      bottom: 10px;
      background: currentColor;
    }
  }

  button[aria-label='Next'] {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs, 1));
    width: 22px;
    height: 22px;

    &::after,
    &::before {
      content: '';
      display: block;
      box-sizing: border-box;
      position: absolute;
      right: 3px;
    }

    &::after {
      width: 8px;
      height: 8px;
      border-top: 2px solid;
      border-right: 2px solid;
      transform: rotate(45deg);
      bottom: 7px;
    }

    &::before {
      width: 16px;
      height: 2px;
      bottom: 10px;
      background: currentColor;
    }
  }
}

.lightbox-gallery-right-tools {
  button:before {
    content: none;
  }

  button.lightbox-gallery-fullscreen-btn {
    &::before {
      content: none !important;
    }

    &::after {
      content: '\e91d' !important;
    }
  }

  button.lightbox-gallery-fullscreen-btn.active {
    &::after {
      content: '\e91c' !important;
    }
  }

  button.lightbox-gallery-zoom-btn {
    &::before {
      content: none;
    }

    &::after {
      content: '\e93b' !important;
    }
  }

  button.lightbox-gallery-zoom-btn.active {
    &::before {
      content: none;
    }

    &::after {
      content: '\e93c' !important;
    }
  }

  button.lightbox-gallery-close-btn {
    &:before {
      content: none;
    }

    &::after {
      content: '\e912' !important;
    }
  }
}

.carousel-control-prev {
  .carousel-control-prev-icon {
    box-sizing: border-box;
    position: relative;
    transform: scale(var(--ggs, 1));
    border: 2px solid transparent;
    background: rgba(0, 145, 234, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 1.125rem;

    &::before {
      content: none !important;
    }

    &::after {
      content: '';
      display: block;
      box-sizing: border-box;
      position: absolute;
      width: 1rem;
      height: 1rem;
      border-bottom: 2px solid;
      border-left: 2px solid;
      transform: rotate(45deg);
      margin-left: 0.5rem;
    }
  }
}

.carousel-control-next {
  .carousel-control-next-icon {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs, 1));
    border: 2px solid transparent;
    background: rgba(0, 145, 234, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 1.125rem;

    &::before {
      content: none !important;
    }

    &::after {
      content: '';
      display: block;
      box-sizing: border-box;
      position: absolute;
      width: 1rem;
      height: 1rem;
      border-bottom: 2px solid;
      border-right: 2px solid;
      transform: rotate(-45deg);
      margin-right: 0.5rem;
    }
  }
}

.demo-modal-wrapper {
  display: flex;
  flex-direction: column;
  width: 21rem;
  padding: 1rem;
  text-align: center;
  border-radius: 0.5rem;
  border: 0.063rem solid #ccd4de;
  background-color: #fff;
  box-shadow: 1px 1px 20.1px 0px rgba(0, 0, 0, 0.1);
}

.demo-modal-text {
  color: #667185;
  font-size: 0.875rem;
}