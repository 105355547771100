.seech-checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;

  .form-check-input[type='radio'], 
  .form-check-input[type='checkbox'] {
    border-width: 1.5px;
    margin-top: 0;
    &:not(:checked) { 
      border-color: #D0D5DD;
    }
  }

  .form-check-input[type='checkbox'] {
    &.circle, &:not(:checked):focus:after {
      border-radius: 50% !important;
    }
    &.circle:checked:after {
      height: 0.6rem;
      margin-top: 1.2px;
    }
  }

  .form-check-input[type='radio'] {
    &.normal {
      border-radius: 4px !important;
    }
  }
}

.sch-color-success {
  & .form-check-input[type='checkbox']:checked {
    background-color: $success !important;
    border-color: $success !important;
  }
  & .form-check-input[type='radio']:checked {
    border-color: $success !important;
  }
}

.sch-color-danger {
  & .form-check-input[type='checkbox']:checked {
    background-color: $danger !important;
    border-color: $danger !important;
  }
  & .form-check-input[type='radio']:checked {
    border-color: $danger !important;
  }
}

.sch-color-warning {
  & .form-check-input[type='checkbox']:checked {
    background-color: $warning !important;
    border-color: $warning !important;
  }
  & .form-check-input[type='radio']:checked {
    border-color: $warning !important;
  }
}

.sch-color-info {
  & .form-check-input[type='checkbox']:checked {
    background-color: $info !important;
    border-color: $info !important;
  }
  & .form-check-input[type='radio']:checked {
    border-color: $info !important;
  }
}

.sch-color-light {
  & .form-check-input[type='checkbox']:checked {
    background-color: $light !important;
    border-color: $light !important;
  }
  & .form-check-input[type='radio']:checked {
    border-color: $light !important;
  }
}

.sch-color-dark {
  & .form-check-input[type='checkbox']:checked {
    background-color: $dark !important;
    border-color: $dark !important;
  }
  & .form-check-input[type='radio']:checked {
    border-color: $dark !important;
  }
}

.form-switch.form-check, .form-checkbox.form-check {
   .form-check-input:focus:before{
      animation-name: ripple;
      animation-duration: 1.5s;
      animation-delay: 2s;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
   }
}
.form-switch.form-check .form-check-input:focus:before {
  animation-name: switchRipple;
}

@keyframes switchRipple {
  from {transform: scale(1) translateY(-50%);}
  to {transform: scale(0) translateY(-50%);}
}

@keyframes ripple {
  from {transform: scale(1)}
  to {transform: scale(0)}
}
