// scss-docs-start theme-colors-map
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'danger': $danger,
  'warning': $warning,
  'info': $info,
  'light': $light,
  'dark': $dark,
) !default;
// scss-docs-end theme-colors-map

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-shadows: true !default;
$enable-negative-margins: true !default;

// Prefix for :root CSS variables.

$variable-prefix: mdb- !default;
$prefix: $variable-prefix !default;

// Body
//
// Settings for the `<body>` element.

$body-color: $gray-800 !default;

// Links
//
// Style anchor elements.

$link-color: $primary !default;
$link-decoration: none !default;
$link-shade-percentage: 5% !default;
$link-shade-focus-percentage: 10% !default;
$link-shade-active-percentage: 15% !default;
$link-hover-decoration: none !default;
$link-hover-color: shift-color($link-color, $link-shade-percentage) !default;
$link-focus-color: shift-color($link-color, $link-shade-focus-percentage) !default;
$link-active-color: shift-color($link-color, $link-shade-active-percentage) !default;
// Components
//
// Define common box shadow and border and more.

// scss-docs-start border-variables
$border-width-alternate: 2px !default;
$border-style-alternate: solid !default;
$border-color-alternate: hsl(0, 0%, 96%) !default;
// scss-docs-end border-variables

// scss-docs-start border-radius-variables
$border-radius: 0.25rem !default;
$border-radius-xs: 0.2rem !default;
$border-radius-md: 0.3rem !default;
$border-radius-lg: 0.5rem !default;
// scss-docs-end border-radius-variables

// Shadows
//
// Shadows offset, blur, spread and color.

// scss-docs-start box-shadow-variables
$box-shadow-0: none !default;

$box-shadow-1: 0 0px 2px 0 rgb(0 0 0 / 7%), 0 1px 1px 0 rgb(0 0 0 / 4%) !default;
$box-shadow-2: 0 0px 3px 0 rgb(0 0 0 / 7%), 0 2px 2px 0 rgb(0 0 0 / 4%) !default;
$box-shadow-3: 0 2px 6px -1px rgb(0 0 0 / 7%), 0 6px 18px -1px rgb(0 0 0 / 4%) !default;
$box-shadow-4: 0 2px 15px -3px rgb(0 0 0 / 7%), 0 10px 20px -2px rgb(0 0 0 / 4%) !default;
$box-shadow-5: 0 2px 25px -5px rgb(0 0 0 / 7%), 0 25px 21px -5px rgb(0 0 0 / 4%) !default;
$box-shadow-6: 0 2px 35px -12px rgb(0 0 0 / 21%), 0 50px 40px -5px rgb(0 0 0 / 4%) !default;

$box-shadow-1-soft: 0 1px 5px 0 rgba(0, 0, 0, 0.05) !default;
$box-shadow-2-soft: 0 2px 10px 0 rgba(0, 0, 0, 0.05) !default;
$box-shadow-3-soft: 0 5px 15px 0 rgba(0, 0, 0, 0.05) !default;
$box-shadow-4-soft: 0 10px 20px 0 rgba(0, 0, 0, 0.05) !default;
$box-shadow-5-soft: 0 15px 30px 0 rgba(0, 0, 0, 0.05) !default;
$box-shadow-6-soft: 0 20px 40px 0 rgba(0, 0, 0, 0.05) !default;

$box-shadow-1-strong: 0 0px 2px 0 rgb(0 0 0 / 16%), 0 1px 1px 0 rgb(0 0 0 / 10%) !default;
$box-shadow-2-strong: 0 0px 3px 0 rgb(0 0 0 / 16%), 0 2px 2px 0 rgb(0 0 0 / 10%) !default;
$box-shadow-3-strong: 0 2px 6px -1px rgb(0 0 0 / 16%), 0 6px 18px -1px rgb(0 0 0 / 10%) !default;
$box-shadow-4-strong: 0 2px 15px -3px rgb(0 0 0 / 16%), 0 10px 20px -2px rgb(0 0 0 / 10%) !default;
$box-shadow-5-strong: 0 2px 25px -5px rgb(0 0 0 / 16%), 0 25px 21px -5px rgb(0 0 0 / 10%) !default;
$box-shadow-6-strong: 0 2px 35px -12px rgb(0 0 0 / 26%), 0 50px 40px -5px rgb(0 0 0 / 10%) !default;

$box-shadow-3-top: 0 -10px 20px 0 rgba(0, 0, 0, 0.05) !default;
$box-shadow-inner: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !default;
// scss-docs-end box-shadow-variables

// scss-docs-start standard-shadows-map
$shadows: (
  '0': $box-shadow-0,
  '1': $box-shadow-1,
  '2': $box-shadow-2,
  '3': $box-shadow-3,
  '4': $box-shadow-4,
  '5': $box-shadow-5,
  '6': $box-shadow-6,
) !default;
// scss-docs-end standard-shadows-map

// scss-docs-start soft-shadows-map
$shadows-soft: (
  '1': $box-shadow-1-soft,
  '2': $box-shadow-2-soft,
  '3': $box-shadow-3-soft,
  '4': $box-shadow-4-soft,
  '5': $box-shadow-5-soft,
  '6': $box-shadow-6-soft,
) !default;
// scss-docs-end soft-shadows-map

// scss-docs-start strong-shadows-map
$shadows-strong: (
  '1': $box-shadow-1-strong,
  '2': $box-shadow-2-strong,
  '3': $box-shadow-3-strong,
  '4': $box-shadow-4-strong,
  '5': $box-shadow-5-strong,
  '6': $box-shadow-6-strong,
) !default;
// scss-docs-end strong-shadows-map

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// scss-docs-start font-variables
// stylelint-disable value-keyword-case
$font-family-roboto: 'Roboto', sans-serif !default;
// stylelint-enable value-keyword-case
$font-family-base: var(--#{$prefix}font-roboto) !default;

// $font-size-base affects the font size of the body text
$font-size-base: 1rem !default;
$font-weight-medium: 500 !default;
$line-height-base: 1.6 !default;
// scss-docs-end font-variables

// scss-docs-start note-variables
$note-padding: 10px !default;
$note-border-width: 6px !default;
$note-border-radius: 5px !default;
$note-strong-font-weight: 600 !default;
// scss-docs-end note-variables

// scss-docs-start note-colors-map
$note: () !default;
$note: map-merge(
  (
    'primary': (
      'background-color': rgb(223, 231, 246),
      'border-color': rgb(55, 111, 200),
    ),
    'secondary': (
      'background-color': rgb(235, 237, 239),
      'border-color': rgb(86, 94, 108),
    ),
    'success': (
      'background-color': rgb(214, 240, 224),
      'border-color': rgb(19, 150, 71),
    ),
    'danger': (
      'background-color': rgb(249, 225, 229),
      'border-color': rgb(214, 46, 74),
    ),
    'warning': (
      'background-color': rgb(251, 240, 218),
      'border-color': rgb(161, 113, 18),
    ),
    'info': (
      'background-color': rgb(222, 241, 247),
      'border-color': rgb(38, 134, 166),
    ),
    'light': (
      'background-color': rgb(245, 245, 245),
      'border-color': rgb(140, 140, 140),
    ),
    'dark': (
      'background-color': rgb(51, 46, 46),
      'border-color': rgb(232, 232, 232),
    ),
  ),
  $note
);
// scss-docs-end note-colors-map

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

// scss-docs-start table-variables
$table-cell-padding-y: 1rem !default;
$table-cell-padding-x: 1.4rem !default;
$table-cell-padding-y-sm: 0.5rem !default;
$table-cell-padding-x-sm: 0.5rem !default;

$table-color: var(--#{$prefix}body-color) !default;
$table-font-size: 0.9rem !default;
$table-striped-bg: rgba(0, 0, 0, 0.02) !default;
$table-hover-transition: 0.5s !default;
$table-hover-bg: rgba(0, 0, 0, 0.025) !default;
$table-group-separator-color: inherit !default;
$table-divider-color: rgba(0, 0, 0, 0.1) !default;
$table-bg-scale: -80% !default;
// scss-docs-end table-variables

// scss-docs-start table-loop
$table-variants: (
  'primary': shift-color($primary, $table-bg-scale),
  'secondary': shift-color($secondary, $table-bg-scale),
  'success': shift-color($success, $table-bg-scale),
  'info': shift-color($info, $table-bg-scale),
  'warning': shift-color($warning, $table-bg-scale),
  'danger': shift-color($danger, $table-bg-scale),
  'light': $light,
  'dark': $dark,
) !default;
// scss-docs-end table-loop

// Buttons
//
// For each of MDB's buttons, define text, background, and border color.

// scss-docs-start btn-variables
$btn-padding-top: 0.625rem !default;
$btn-padding-bottom: 0.5rem !default;
$btn-padding-x: 1.5rem !default;
$btn-font-size: 0.75rem !default;
$btn-line-height: 1.5 !default;

$btn-padding-top-lg: 0.75rem !default;
$btn-padding-bottom-lg: 0.6875rem !default;
$btn-padding-x-lg: 1.6875rem !default;
$btn-font-size-lg: 0.875rem !default;
$btn-line-height-lg: 1.6 !default;

$btn-padding-top-sm: 0.375rem !default;
$btn-padding-bottom-sm: 0.3125rem !default;
$btn-padding-x-sm: 1rem !default;
$btn-font-size-sm: 0.75rem !default;
$btn-line-height-sm: 1.5 !default;

$btn-border-width: $border-width-alternate !default;

$btn-font-weight: $font-weight-medium !default;
$btn-box-shadow: 0 4px 9px -4px rgba($black, 0.35) !default;
$btn-hover-box-shadow: 0 8px 9px -4px rgba($black, 0.15), 0 4px 18px 0 rgba($black, 0.1) !default;
$btn-focus-box-shadow: $btn-hover-box-shadow !default;
$btn-active-box-shadow: $btn-focus-box-shadow !default;

$btn-link-color: $link-color !default;
$btn-link-hover-color: $link-hover-color !default;
$btn-link-focus-color: $link-focus-color !default;
$btn-link-active-color: $link-active-color !default;
$btn-link-disabled-color: $gray-500 !default;
$btn-link-hover-bg: hsl(0, 0%, 96%) !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: $border-radius !default;

$btn-hover-bg-shade-amount: 5% !default;
$btn-focus-bg-shade-amount: 5% !default;
$btn-active-bg-shade-amount: 10% !default;

$btn-contextual-box-shadow: 0 4px 9px -4px !default;
$btn-contextual-box-shadow-state-first-part: 0 8px 9px -4px !default;
$btn-contextual-box-shadow-state-second-part: 0 4px 18px 0 !default;

$btn-outline-padding-top: 0.5rem !default;
$btn-outline-padding-bottom: 0.375rem !default;
$btn-outline-padding-x: 1.375rem !default;
$btn-outline-border-width: $border-width-alternate !default;
$btn-outline-line-height: 1.5 !default;

$btn-outline-padding-top-lg: 0.625rem !default;
$btn-outline-padding-bottom-lg: 0.5625rem !default;
$btn-outline-padding-x-lg: 1.5625rem !default;
$btn-outline-font-size-lg: 0.875rem !default;
$btn-outline-line-height-lg: 1.6 !default;

$btn-outline-padding-top-sm: 0.25rem !default;
$btn-outline-padding-bottom-sm: 0.1875rem !default;
$btn-outline-padding-x-sm: 0.875rem !default;
$btn-outline-font-size-sm: 0.75rem !default;
$btn-outline-line-height-sm: 1.5 !default;

$btn-rounded-border-radius: 10rem !default;

$btn-floating-border-radius: 50% !default;

$btn-floating-width: 2.3125rem !default;
$btn-floating-height: 2.3125rem !default;
$btn-floating-icon-width: 2.3125rem !default;

$btn-floating-width-lg: 2.8125rem !default;
$btn-floating-height-lg: 2.8125rem !default;
$btn-floating-icon-width-lg: 2.8125rem !default;

$btn-floating-width-sm: 1.8125rem !default;
$btn-floating-height-sm: 1.8125rem !default;
$btn-floating-icon-width-sm: 1.8125rem !default;

$btn-outline-floating-icon-width: 2.0625rem !default;
$btn-outline-floating-icon-width-lg: 2.5625rem !default;
$btn-outline-floating-icon-width-sm: 1.5625rem !default;

$btn-floating-icon-line-height: 2.3125rem !default;
$btn-floating-icon-line-height-lg: 2.8125rem !default;
$btn-floating-icon-line-height-sm: 1.8125rem !default;

$btn-outline-floating-icon-line-height: 2.0625rem !default;
$btn-outline-floating-icon-line-height-lg: 2.5625rem !default;
$btn-outline-floating-icon-line-height-sm: 1.5625rem !default;

$fixed-action-btn-right: 2.1875rem !default;
$fixed-action-btn-bottom: 2.1875rem !default;
$fixed-action-btn-padding-top: 0.9375rem !default;
$fixed-action-btn-padding-bottom: 1.25rem !default;
$fixed-action-btn-padding-x: 1.25rem !default;
$fixed-action-btn-li-margin-bottom: 1.5rem !default;

$btn-block-margin-top: 0.5rem !default;
// scss-docs-end btn-variables

// scss-docs-start buttons-colors-map
$buttons: () !default;
$buttons: map-merge(
  (
    'primary': (
      'background-color': $primary,
      'text-color': $white,
      'shadow-color': $primary,
    ),
    'secondary': (
      'background-color': #e3ebf7,
      'text-color': #285192,
      'shadow-color': #e3ebf7,
    ),
    'success': (
      'background-color': $success,
      'text-color': $white,
      'shadow-color': $success,
    ),
    'danger': (
      'background-color': $danger,
      'text-color': $white,
      'shadow-color': $danger,
    ),
    'warning': (
      'background-color': $warning,
      'text-color': $white,
      'shadow-color': $warning,
    ),
    'info': (
      'background-color': $info,
      'text-color': $white,
      'shadow-color': $info,
    ),
    'light': (
      'background-color': $light,
      'text-color': #4f4f4f,
      'shadow-color': $light,
    ),
    'dark': (
      'background-color': $dark,
      'text-color': $white,
      'shadow-color': $dark,
    ),
    'white': (
      'background-color': $white,
      'text-color': $black,
      'shadow-color': $white,
    ),
    'black': (
      'background-color': $black,
      'text-color': $white,
      'shadow-color': $black,
    ),
  ),
  $buttons
);
// scss-docs-end buttons-colors-map

// scss-docs-start buttons-outline-colors-map
$buttons-outline: () !default;
$buttons-outline: map-merge(
  (
    'primary': (
      'text-color': $primary,
      'border-color': $primary,
    ),
    'secondary': (
      'text-color': #285192,
      'border-color': #e3ebf7,
    ),
    'success': (
      'text-color': $success,
      'border-color': $success,
    ),
    'danger': (
      'text-color': $danger,
      'border-color': $danger,
    ),
    'warning': (
      'text-color': $warning,
      'border-color': $warning,
    ),
    'info': (
      'text-color': $info,
      'border-color': $info,
    ),
    'light': (
      'text-color': $light,
      'border-color': $light,
    ),
    'dark': (
      'text-color': $dark,
      'border-color': $dark,
    ),
    'white': (
      'text-color': $white,
      'border-color': $white,
    ),
    'black': (
      'text-color': $black,
      'border-color': $black,
    ),
  ),
  $buttons-outline
);
// scss-docs-end buttons-outline-colors-map

// Ripple

// scss-docs-start ripple-variables
$ripple-wave-cubicBezier: cubic-bezier(0, 0, 0.15, 1) !default;
$ripple-wave-border-radius: 50% !default;
$ripple-wave-opacity: 0.5 !default;
$ripple-wave-transform: scale(0) !default;
$ripple-wave-z-index: 999 !default;
$ripple-wave-active-transform: scale(1) !default;
// scss-docs-end ripple-variables

// scss-docs-start ripple-colors-map
$ripple: (
  'primary': $primary,
  'secondary': #e3ebf7,
  'success': $success,
  'danger': $danger,
  'warning': $warning,
  'info': $info,
  'light': $light,
  'dark': $dark,
) !default;
// scss-docs-end ripple-colors-map

// Button group
//
// Material styling for group of buttons

// scss-docs-start btn-group-variables
$btn-group-border-radius: calc(4px - 1px) !default;
$btn-group-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;
// scss-docs-end btn-group-variables

// Forms
//
// Material styling for outline forms border, label, size

// scss-docs-start input-variables
$input-padding-top: 0.32rem !default;
$input-padding-bottom: $input-padding-top !default;
$input-padding-left: 0.75rem !default;
$input-padding-right: $input-padding-left !default;
$input-transition: all 0.2s linear !default;

$form-label-left: 0.75rem !default;
$form-label-padding-top: 0.37rem !default;
$form-label-transition: all 0.2s ease-out !default;
$form-label-color: rgba(0, 0, 0, 0.6) !default;

$input-focus-active-label-transform: translateY(-1rem) translateY(0.1rem) scale(0.8) !default;
$input-focus-label-color: $primary !default;
$input-focus-border-width: 0.125rem !default;
$input-focus-border-color: $primary !default;
$input-disabled-background-color: #e9ecef !default;

$input-font-size-lg: 1rem !default;
$input-line-height-lg: 2.15 !default;
$input-focus-active-label-transform-lg: translateY(-1.25rem) translateY(0.1rem) scale(0.8) !default;

$input-padding-top-sm: 0.32rem !default;
$input-padding-bottom-sm: 0.32rem !default;
$input-font-size-sm: 0.775rem !default;
$input-line-height-sm: 1.5 !default;
$input-focus-active-label-transform-sm: translateY(-0.85rem) translateY(0.1rem) scale(0.8) !default;

$form-notch-div-border-color: #bdbdbd !default;
$form-notch-leading-width: 0.5rem !default;
$form-notch-leading-border-radius: 0.25rem !default;
$form-notch-middle-max-width: 1rem !default;
$form-notch-trailing-border-radius: $form-notch-leading-border-radius !default;

$form-label-padding-top-lg: 0.7rem !default;
$form-label-padding-top-sm: 0.33rem !default;
$form-label-font-size-sm: 0.775rem !default;

$form-white-input-color: $white !default;
$form-white-label-color: $gray-50 !default;
$form-white-notch-div-border-color: $gray-50 !default;
$form-white-input-focus-label-color: $white !default;
$form-white-input-focus-border-color: $white !default;
$form-white-placeholder-color: rgba(255, 255, 255, 0.7) !default;
$form-white-disabled-bgc: rgba(255, 255, 255, 0.45) !default;

$form-check-input-margin-left: 1.79rem !default;
$form-check-input-radio-margin-left: 1.85rem !default;
$form-check-input-width-md: 1.125rem !default;
$form-check-input-height: $form-check-input-width-md !default;
$form-check-input-background-color: $white !default;
$form-check-input-border-width: 0.125rem !default;
$form-check-input-border-color: rgba(0, 0, 0, 0.25) !default;

$form-check-input-before-box-shadow: 0px 0px 0px 13px transparent !default;
$form-check-input-before-border-radius: 50% !default;
$form-check-input-before-width: 0.875rem !default;
$form-check-input-before-height: $form-check-input-before-width !default;
$form-check-input-before-transform: scale(0) !default;

$form-check-input-hover-before-opacity: 0.04 !default;
$form-check-input-hover-before-box-shadow: 0px 0px 0px 13px rgba(0, 0, 0, 0.6) !default;

$form-check-input-focus-border-color: $form-check-input-border-color !default;
$form-check-input-focus-transition: border-color 0.2s !default;

$form-check-input-focus-before-opacity: 0.12 !default;
$form-check-input-focus-before-box-shadow: $form-check-input-hover-before-box-shadow !default;
$form-check-input-focus-before-transform: scale(1) !default;
$form-check-input-focus-before-transition: box-shadow 0.2s, transform 0.2s !default;

$form-check-input-checked-border-color: $primary !default;
$form-check-input-checked-before-opacity: 0.16 !default;
$form-check-input-checked-focus-border-color: $primary !default;
$form-check-input-checked-focus-before-box-shadow: 0px 0px 0px 13px $primary !default;
$form-check-input-checked-focus-before-transform: $form-check-input-focus-before-transform !default;
$form-check-input-checked-focus-before-transition: $form-check-input-focus-before-transition !default;

$form-check-input-checkbox-border-radius: 0.25rem !default;
$form-check-input-checkbox-focus-after-width: 0.875rem !default;
$form-check-input-checkbox-focus-after-height: $form-check-input-checkbox-focus-after-width !default;
$form-check-input-checkbox-focus-after-background-color: $white !default;
$form-check-input-checkbox-checked-background-color: $primary !default;
$form-check-input-checkbox-checked-focus-background-color: $primary !default;

$form-check-input-checkbox-checked-after-transform: rotate(45deg) !default;
$form-check-input-checkbox-checked-after-border-width: 0.125rem !default;
$form-check-input-checkbox-checked-after-border-color: $white !default;
$form-check-input-checkbox-checked-after-width: 0.375rem !default;
$form-check-input-checkbox-checked-after-height: 0.8125rem !default;
$form-check-input-checkbox-checked-after-margin-left: 0.25rem !default;
$form-check-input-checkbox-checked-after-margin-top: -1px !default;

$form-check-input-indeterminate-focus-before-box-shadow: 0px 0px 0px 13px $primary !default;
$form-check-input-indeterminate-checked-background-color: $primary !default;
$form-check-input-indeterminate-checked-after-transform: rotate(90deg) !default;
$form-check-input-indeterminate-checked-after-border-width: 0.125rem !default;
$form-check-input-indeterminate-checked-after-border-color: $white !default;
$form-check-input-indeterminate-checked-after-width: 0.125rem !default;
$form-check-input-indeterminate-checked-after-height: 0.875rem !default;
$form-check-input-indeterminate-checked-after-margin-left: 0.375rem !default;
$form-check-input-indeterminate-focus-background-color: $primary !default;
$form-check-input-indeterminate-focus-border-color: $primary !default;
$form-check-input-indeterminate-border-color: $gray-600 !default;

$form-check-input-radio-border-radius: 50% !default;
$form-check-input-radio-width: 1.25rem !default;
$form-check-input-radio-height: $form-check-input-radio-width !default;

$form-check-input-radio-before-width: 1rem !default;
$form-check-input-radio-before-height: $form-check-input-radio-before-width !default;

$form-check-input-radio-after-width: $form-check-input-radio-before-width !default;
$form-check-input-radio-after-height: $form-check-input-radio-after-width !default;
$form-check-input-radio-after-border-radius: $form-check-input-radio-border-radius !default;
$form-check-input-radio-after-background-color: $white !default;

$form-check-input-radio-checked-background-color: $white !default;
$form-check-input-radio-checked-after-border-radius: $form-check-input-radio-border-radius !default;
$form-check-input-radio-checked-after-width: 0.625rem !default;
$form-check-input-radio-checked-after-height: $form-check-input-radio-checked-after-width !default;
$form-check-input-radio-checked-after-border-color: $primary !default;
$form-check-input-radio-checked-after-background-color: $primary !default;
$form-check-input-radio-checked-after-margin-top: 0.1875rem !default;
$form-check-input-radio-checked-after-margin-left: 0.1875rem !default;
$form-check-input-radio-checked-after-transition: border-color !default;
$form-check-input-radio-checked-focus-background-color: $white !default;

$form-switch-padding-left: 1.85rem !default;
$form-switch-form-check-input-border-radius: 0.4375rem !default;
$form-switch-form-check-input-width: 2rem !default;
$form-switch-form-check-input-height: 0.875rem !default;
$form-switch-form-check-input-background-color: rgba(0, 0, 0, 0.25) !default;
$form-switch-form-check-input-margin-top: 0.35rem !default;
$form-switch-form-check-input-margin-right: 0.7rem !default;

$form-switch-form-check-input-after-border-radius: 50% !default;
$form-switch-form-check-input-after-width: 1.25rem !default;
$form-switch-form-check-input-after-height: $form-switch-form-check-input-after-width !default;
$form-switch-form-check-input-after-background-color: $white !default;
$form-switch-form-check-input-after-margin-top: -0.1875rem !default;
$form-switch-form-check-input-after-box-shadow: $box-shadow-2 !default;
$form-switch-form-check-input-after-transition: background-color 0.2s, transform 0.2s !default;

$form-switch-form-check-input-focus-before-box-shadow: 3px -1px 0px 13px rgba(0, 0, 0, 0.6) !default;
$form-switch-form-check-input-focus-before-transform: scale(1) !default;
$form-switch-form-check-input-focus-before-transition: box-shadow 0.2s, transform 0.2s !default;

$form-switch-form-check-input-focus-after-border-radius: $form-switch-form-check-input-after-border-radius !default;
$form-switch-form-check-input-focus-after-width: 1.25rem !default;
$form-switch-form-check-input-focus-after-height: $form-switch-form-check-input-focus-after-width !default;

$form-switch-form-check-input-checked-focus-before-margin-left: 1.0625rem !default;
$form-switch-form-check-input-checked-focus-before-box-shadow: 3px -1px 0px 13px $primary !default;
$form-switch-form-check-input-checked-focus-before-transform: scale(1) !default;
$form-switch-form-check-input-checked-focus-before-transition: box-shadow 0.2s, transform 0.2s !default;

$form-switch-form-check-input-checked-checkbox-after-border-radius: 50% !default;
$form-switch-form-check-input-checked-checkbox-after-width: 1.25rem !default;
$form-switch-form-check-input-checked-checkbox-after-height: 1.25rem !default;
$form-switch-form-check-input-checked-checkbox-after-background-color: $primary !default;
$form-switch-form-check-input-checked-checkbox-after-margin-top: -3px !default;
$form-switch-form-check-input-checked-checkbox-after-margin-left: 1.0625rem !default;
$form-switch-form-check-input-checked-checkbox-after-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
  0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !default;
$form-switch-form-check-input-checked-checkbox-after-transition: background-color 0.2s,
  transform 0.2s !default;

$form-file-height: calc(1.79rem + 0.33rem + 2px) !default;

$form-file-label-focus-border-color: $primary !default;
$form-file-label-focus-transition: all 0.2s linear !default;
$form-file-label-focus-text-border-width: 2px 1px 2px 2px !default;
$form-file-label-focus-button-border-width: 2px 2px 2px 1px !default;
$form-file-label-disabled-background-color: #e9ecef !default;
$form-file-label-height: $form-file-height !default;
$form-file-label-border-color: #bdbdbd !default;
$form-file-label-border-radius: 0.2rem !default;

$form-file-text-padding-y: 0.33rem !default;
$form-file-text-border-color: #bdbdbd !default;

$form-file-button-line-height: 1.5 !default;
$form-file-button-background-color: #fff !default;
$form-file-button-border-color: $form-file-text-border-color !default;

$form-file-sm-height: calc(1.33rem + 0.33rem + 2px) !default;
$form-file-sm-font-size: 0.775rem !default;
$form-file-sm-line-height: 1.5 !default;
$form-file-sm-padding-y: $form-file-text-padding-y !default;

$form-file-lg-height: calc(2.315rem + 0.33rem + 2px) !default;
$form-file-lg-font-size: 1rem !default;
$form-file-lg-line-height: 2.15 !default;
$form-file-lg-padding-y: $form-file-text-padding-y !default;

$form-select-transition: all 0.2s linear !default;
$form-select-focus-border-color: $primary !default;
$form-select-focus-border-box-shadow: inset 0px 0px 0px 1px $primary !default;

$form-range-webkit-slider-thumb-margin-top: -6px !default;
$form-range-webkit-slider-runnable-track-height: 4px !default;

$input-group-height: calc(1.75rem + 0.33rem + 2px) !default;
$input-group-min-height: $input-group-height !default;
$input-group-padding-y: 0.27rem !default;
$input-group-transition: all 0.2s linear !default;

$input-group-focus-transition: $input-group-transition !default;
$input-group-focus-border-color: $primary !default;
$input-group-focus-box-shadow: inset 0 0 0 1px $primary !default;

$input-group-text-padding-y: 0.26rem !default;

$input-group-lg-height: calc(2.315rem + 0.33rem + 2px) !default;
$input-group-lg-font-size: 1rem !default;
$input-group-lg-padding-y: 0.33rem !default;
$input-group-lg-text-font-size: $input-group-lg-font-size !default;

$input-group-sm-height: calc(1.33rem + 0.33rem + 2px) !default;
$input-group-sm-min-height: $input-group-sm-height !default;
$input-group-sm-font-size: 0.775rem !default;
$input-group-sm-padding-y: $input-group-lg-padding-y !default;
$input-group-sm-text-font-size: $input-group-sm-font-size !default;
$input-group-sm-text-line-height: 1.5 !default;

$input-group-form-outline-border-left-width: 1px !default;
$input-group-form-outline-border-left-color: #bdbdbd !default;
// scss-docs-end input-variables

// Form validation

// scss-docs-start form-feedback-variables
$form-feedback-valid-tooltip-color: $white !default;
$form-feedback-valid-color: $success !default;
$form-feedback-invalid-color: $danger !default;
// scss-docs-end form-feedback-variables

// scss-docs-start form-validation-states
$form-validation-states-mdb: (
  'valid': (
    'color': $form-feedback-valid-color,
  ),
  'invalid': (
    'color': $form-feedback-invalid-color,
  ),
) !default;
// scss-docs-end form-validation-states

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

// scss-docs-start zindex-stack
$fixed-action-button-zindex: 1030 !default;
$zindex-toast: 1060 !default;
$alert-zindex: 1070 !default;
$zindex-popover: 1080 !default;
$zindex-tooltip: 1090 !default;
// scss-docs-end zindex-stack

// Navs

// scss-docs-start nav-variables
$nav-tabs-link-border-bottom-width: 2px !default;
$nav-tabs-link-font-weight: 500 !default;
$nav-tabs-link-font-size: 12px !default;
$nav-tabs-link-padding-top: 17px !default;
$nav-tabs-link-padding-bottom: 16px !default;
$nav-tabs-link-padding-x: 29px !default;
$nav-tabs-link-hover-background-color: hsl(0, 0%, 96%) !default;
$nav-tabs-link-active-color: $primary !default;
$nav-tabs-link-active-border-color: $primary !default;

$nav-pills-margin: 0.5rem !default;
$nav-pills-link-border-radius: 0.25rem !default;
$nav-pills-link-font-size: $nav-tabs-link-font-size !default;
$nav-pills-link-padding-top: $nav-tabs-link-padding-top !default;
$nav-pills-link-padding-bottom: $nav-tabs-link-padding-bottom !default;
$nav-pills-link-padding-x: $nav-tabs-link-padding-x !default;
$nav-pills-link-line-height: 1 !default;
$nav-pills-link-background-color: $nav-tabs-link-hover-background-color !default;
$nav-pills-link-font-weight: $nav-tabs-link-font-weight !default;

$nav-tabs-link-color: rgba(0, 0, 0, 0.55) !default;
$nav-tabs-link-hover-bgc: #f7f7f7 !default;

$nav-pills-link-color: $nav-tabs-link-color !default;
$nav-pills-link-hover-bg: $nav-tabs-link-hover-bgc !default;
$nav-pills-link-active-bg: #e3ebf7 !default;
$nav-pills-link-active-color: #285192 !default;
// scss-docs-end nav-variables

// Navbar

// scss-docs-start navbar-variables
$navbar-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.05) !default;
$navbar-padding-top: 0.5625rem !default;
$navbar-brand-img-margin-right: 0.25rem !default;
// scss-docs-end navbar-variables

// Dropdowns
//
// Dropdown menu container and contents.

// scss-docs-start dropdown-variables
$dropdown-color: $body-color !default;
$dropdown-margin-top: 0.125rem !default;
$dropdown-box-shadow: $box-shadow-4 !default;
$dropdown-font-size: 0.875rem !default;
$dropdown-item-border-radius: 0.5rem !default;
$dropdown-item-padding-y: 0.5rem !default;
$dropdown-item-padding-x: 1rem !default;
$dropdown-border-radius: 0.5rem !default;
$dropdown-divider-bg: hsl(0, 0%, 96%) !default;
$dropdown-divider-border-top-width: 2px;

$dropdown-state-color: #16181b !default;
$dropdown-state-background-color: #eee !default;

$dropdown-menu-animated-animation-duration: 0.55s !default;
$dropdown-menu-animated-animation-timing-function: ease !default;

$dropdown-animation-duration: 1s !default;
// scss-docs-end dropdown-variables

// Pagination

// scss-docs-start pagination-variables
$pagination-font-size: 0.9rem !default;
$pagination-color: $body-color !default;
$pagination-transition: all 0.3s linear !default;
$pagination-border-radius: 0.25rem !default;

$pagination-hover-color: $pagination-color !default;
$pagination-active-transition: all 0.2s linear !default;

$pagination-circle-border-radius: 50% !default;
$pagination-circle-padding-x: 0.841rem !default;

$pagination-circle-lg-padding-left: 1.399414rem !default;
$pagination-circle-lg-padding-right: 1.399415rem !default;

$pagination-circle-sm-padding-left: 0.696rem !default;
$pagination-circle-sm-padding-right: 0.688rem !default;

$pagination-active-bg: #e3ebf7 !default;
$pagination-active-color: #285192 !default;
$pagination-active-box-shadow: 0 4px 9px -4px #e3ebf7 !default;
$pagination-active-font-weight: 500 !default;
// scss-docs-end pagination-variables

// Placeholders

// scss-docs-start placeholders
$placeholder-opacity-max: 0.5 !default;
$placeholder-opacity-min: 0.2 !default;
// scss-docs-end placeholders

// Cards

// scss-docs-start card-variables
$card-spacer-y: 1.5rem !default;
$card-spacer-x: 1.5rem !default;
$card-box-shadow: $box-shadow-4 !default;
$card-cap-bg: rgba(255, 255, 255, 0) !default;
$card-footer-background-color: rgba(255, 255, 255, 0) !default;
$card-header-border-width: 2px !default;
$card-header-border-color: hsl(0, 0%, 96%) !default;
$card-footer-border-width: 2px !default;
$card-footer-border-color: hsl(0, 0%, 96%) !default;
$card-border-radius: 0.5rem !default;
$card-img-overlay-padding: 1.5rem !default;
// scss-docs-end card-variables

// Accordion

// scss-docs-start accordion-variables
$accordion-padding-y: 1.15rem !default;
$accordion-padding-x: 1.5rem !default;
$accordion-button-active-bg: $white !default;
$accordion-button-active-color: $primary !default;
$accordion-button-focus-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125) !default;
$accordion-border-radius: $border-radius-lg !default;
$accordion-flush-button-box-shadow: inset 0 -2px 0 hsl(0, 0%, 96%) !default;
$accordion-flush-item-border-bottom: 2px solid hsl(0, 0%, 96%) !default;
$accordion-borderless-button-border-radius: 0.5rem !default;
$accordion-borderless-button-bgc: rgb(223, 231, 246) !default;
$accordion-borderless-button-color: rgb(44, 88, 160) !default;
// scss-docs-end accordion-variables

// Tooltips

// scss-docs-start tooltip-variables
$tooltip-color: #fff !default;
$tooltip-padding-y: 6px !default;
$tooltip-padding-x: 16px !default;
$tooltip-font-size: 14px !default;
$tooltip-bg: #6d6d6d !default;
$tooltip-border-radius: 0.25rem !default;
// scss-docs-end tooltip-feedback-variables

// Popovers

// scss-docs-start popover-variables
$popover-box-shadow: $box-shadow-2 !default;
$popover-border-radius: $border-radius-lg !default;
$popover-border-bottom-width: $border-width-alternate !default;
$popover-border-width: 1px !default;
$popover-border-color: hsl(0, 0%, 96%) !default;
$popover-header-bg: $white !default;
// scss-docs-end popover-variables

// Toasts

// scss-docs-start toast-variables
$toast-box-shadow: $box-shadow-4 !default;
$toast-border-radius: $border-radius-lg !default;
$toast-border-bottom-width: $border-width-alternate !default;
$toast-background-color: $white !default;
$toast-padding-x: 1rem !default;
$toast-padding-y: 0.65rem !default;
$toast-header-background-color: $white !default;
$toast-btn-close-width: 1.3em !default;
$toast-btn-close-mr: -0.375rem !default;
$toast-btn-close-ml: 0.75rem !default;
// scss-docs-end toast-variables

// scss-docs-start toasts-colors-map
$toasts: () !default;
$toasts: map-merge(
  (
    'primary': (
      'background-color': hsl(219, 56.1%, 92%),
      'text-color': hsl(217, 56.9%, 40%),
      'icon-color': hsl(217, 56.9%, 50%),
      'border-color': hsl(218, 57.7%, 86.1%),
    ),
    'secondary': (
      'background-color': hsl(210, 11.1%, 92.9%),
      'text-color': hsl(216, 10.5%, 28%),
      'icon-color': hsl(218, 11.3%, 38%),
      'border-color': hsl(206, 10.4%, 86.9%),
    ),
    'success': (
      'background-color': hsl(143, 46.4%, 89%),
      'text-color': hsl(144, 77.8%, 22.9%),
      'icon-color': hsl(144, 77.5%, 33.1%),
      'border-color': hsl(145, 44.8%, 82.9%),
    ),
    'danger': (
      'background-color': hsl(350, 66.7%, 92.9%),
      'text-color': hsl(350, 66.7%, 41.2%),
      'icon-color': hsl(350, 67.2%, 51%),
      'border-color': hsl(350, 66.7%, 87.1%),
    ),
    'warning': (
      'background-color': hsl(40, 80.5%, 92%),
      'text-color': hsl(40, 79.7%, 25.1%),
      'icon-color': hsl(40, 79.9%, 35.1%),
      'border-color': hsl(39, 83.1%, 86.1%),
    ),
    'info': (
      'background-color': hsl(194, 61%, 92%),
      'text-color': hsl(195, 63.4%, 30%),
      'icon-color': hsl(195, 62.7%, 40%),
      'border-color': hsl(195, 60.6%, 86.1%),
    ),
    'light': (
      'background-color': hsl(0, 0%, 96.1%),
      'text-color': hsl(0, 0%, 31%),
      'icon-color': hsl(0, 0%, 54.9%),
      'border-color': hsl(0, 0%, 90.2%),
    ),
    'dark': (
      'background-color': hsl(0, 5.2%, 19%),
      'text-color': hsl(0, 0%, 96.1%),
      'icon-color': hsl(0, 0%, 91%),
      'border-color': hsl(0, 6.3%, 25.1%),
    ),
  ),
  $toasts
);
// scss-docs-end toasts-colors-map

// Badges

// scss-docs-start badge-variables
$badge-border-radius: 0.27rem !default;

$badge-dot-border-radius: 4.5px !default;
$badge-dot-height: 9px !default;
$badge-dot-width: $badge-dot-height !default;
$badge-dot-margin-left: -0.3125rem !default;

$badge-notification-font-size: 0.6rem !default;
$badge-notification-margin-top: -0.1rem !default;
$badge-notification-margin-left: -0.5rem !default;
$badge-notification-padding-y: 0.2em !default;
$badge-notification-padding-x: 0.45em !default;
// scss-docs-end badge-variables

// scss-docs-start badges-colors-map
$badges: () !default;
$badges: map-merge(
  (
    'primary': (
      'background-color': rgb(223, 231, 246),
      'text-color': rgb(44, 88, 160),
      'icon-color': rgb(55, 111, 200),
      'border-color': rgb(199, 214, 240),
    ),
    'secondary': (
      'background-color': rgb(235, 237, 239),
      'text-color': rgb(64, 70, 79),
      'icon-color': rgb(86, 94, 108),
      'border-color': rgb(218, 222, 225),
    ),
    'success': (
      'background-color': rgb(214, 240, 224),
      'text-color': rgb(13, 104, 50),
      'icon-color': rgb(19, 150, 71),
      'border-color': rgb(192, 231, 208),
    ),
    'danger': (
      'background-color': rgb(249, 225, 229),
      'text-color': rgb(175, 35, 58),
      'icon-color': rgb(214, 46, 74),
      'border-color': rgb(244, 200, 207),
    ),
    'warning': (
      'background-color': rgb(251, 240, 218),
      'text-color': rgb(115, 81, 13),
      'icon-color': rgb(161, 113, 18),
      'border-color': rgb(249, 228, 190),
    ),
    'info': (
      'background-color': rgb(222, 241, 247),
      'text-color': rgb(28, 101, 125),
      'icon-color': rgb(38, 134, 166),
      'border-color': rgb(198, 230, 241),
    ),
    'light': (
      'background-color': rgb(245, 245, 245),
      'text-color': rgb(79, 79, 79),
      'icon-color': rgb(140, 140, 140),
      'border-color': rgb(230, 230, 230),
    ),
    'dark': (
      'background-color': rgb(51, 46, 46),
      'text-color': rgb(245, 245, 245),
      'icon-color': rgb(232, 232, 232),
      'border-color': rgb(68, 60, 60),
    ),
  ),
  $badges
);
// scss-docs-end badges-colors-map

// Modals

// scss-docs-start modal-variables
$modal-box-shadow: $box-shadow-4 !default;
$modal-content-border-radius: $border-radius-lg !default;
$modal-header-border-color: $border-color-alternate !default;
$modal-footer-border-color: $border-color-alternate !default;
$modal-header-border-width: $border-width-alternate !default;
$modal-footer-border-width: $border-width-alternate !default;
// scss-docs-end modal-variables

// Alerts

// scss-docs-start alert-variables
$alert-padding-y: 1.25rem !default;
$alert-padding-x: 1.5rem !default;
$alert-border-radius: $border-radius-lg !default;
// scss-docs-end alert-variables

// scss-docs-start alerts-colors-map
$alerts: () !default;
$alerts: map-merge(
  (
    'primary': (
      'background-color': rgb(223, 231, 246),
      'text-color': rgb(44, 88, 160),
      'icon-color': rgb(55, 111, 200),
      'border-color': rgb(199, 214, 240),
    ),
    'secondary': (
      'background-color': rgb(235, 237, 239),
      'text-color': rgb(64, 70, 79),
      'icon-color': rgb(86, 94, 108),
      'border-color': rgb(218, 222, 225),
    ),
    'success': (
      'background-color': rgb(214, 240, 224),
      'text-color': rgb(13, 104, 50),
      'icon-color': rgb(19, 150, 71),
      'border-color': rgb(192, 231, 208),
    ),
    'danger': (
      'background-color': rgb(249, 225, 229),
      'text-color': rgb(175, 35, 58),
      'icon-color': rgb(214, 46, 74),
      'border-color': rgb(244, 200, 207),
    ),
    'warning': (
      'background-color': rgb(251, 240, 218),
      'text-color': rgb(115, 81, 13),
      'icon-color': rgb(161, 113, 18),
      'border-color': rgb(249, 228, 190),
    ),
    'info': (
      'background-color': rgb(222, 241, 247),
      'text-color': rgb(28, 101, 125),
      'icon-color': rgb(38, 134, 166),
      'border-color': rgb(198, 230, 241),
    ),
    'light': (
      'background-color': rgb(245, 245, 245),
      'text-color': rgb(79, 79, 79),
      'icon-color': rgb(140, 140, 140),
      'border-color': rgb(230, 230, 230),
    ),
    'dark': (
      'background-color': rgb(51, 46, 46),
      'text-color': rgb(245, 245, 245),
      'icon-color': rgb(232, 232, 232),
      'border-color': rgb(68, 60, 60),
    ),
  ),
  $alerts
);
// scss-docs-end alerts-colors-map

// Progress bars

// scss-docs-start progress-variables
$progress-height: 4px !default;
// scss-docs-end progress-variables

// Range

// scss-docs-start range-variables
$range-thumb-height: 30px !default;
$range-thumb-width: 30px !default;
$range-thumb-top: -35px !default;
$range-thumb-background: $primary !default;
$range-thumb-active-background: tint-color($primary, 70%) !default;
$range-thumb-margin-left: -15px !default;
$range-thumb-border-radius: 50% 50% 50% 0 !default;
$range-thumb-transform: scale(0) !default;
$range-thumb-transition: transform 0.2s ease-in-out !default;
$range-thumb-value-font-size: 12px !default;
$range-thumb-value-line-height: 30px !default;
$range-thumb-value-color: rgb(255, 255, 255) !default;
$range-thumb-value-font-weight: 500 !default;
// override default bootstrap values for form range
$form-range-thumb-bg: $range-thumb-background !default;
$form-range-thumb-active-bg: $range-thumb-active-background !default;
// scss-docs-end range-variables

// List group

// scss-docs-start list-group-variables
$list-group-border-radius: $border-radius-lg !default;
$list-group-item-padding-x: 1.5rem !default;
$list-group-item-transition-time: 0.5s !default;
$list-group-color: $body-color !default;

$list-group-light-item-py: 1rem !default;
$list-group-light-item-border: 2px solid hsl(0, 0%, 96%) !default;
$list-group-light-item-border-width: 2px !default;
$list-group-light-active-border-radius: 0.5rem !default;
$list-group-light-active-bg: rgb(223, 231, 246) !default;
$list-group-light-active-color: rgb(44, 88, 160) !default;

$list-group-small-item-py: 0.5rem !default;

$list-group-active-bg: rgb(223, 231, 246) !default;
$list-group-active-color: rgb(44, 88, 160) !default;
$list-group-active-border-color: $list-group-active-bg !default;
// scss-docs-end list-group-variables

// Images

// scss-docs-start images-variables
$image-hover-overlay-transition: all 0.3s ease-in-out !default;

$image-hover-zoom-transition: all 0.3s linear !default;
$image-hover-zoom-transform: scale(1.1) !default;

$image-hover-shadow-transition: $image-hover-overlay-transition !default;
$image-hover-shadow-box-shadow: $box-shadow-4-strong !default;
$image-hover-shadow-box-shadow-soft: $box-shadow-5 !default;
// scss-docs-end images-variables

// Breadcrumbs

// scss-docs-start breadcrumb-variables
$breadcrumb-item-color: rgba(0, 0, 0, 0.55) !default;
$breadcrumb-item-transition: color 0.15s ease-in-out !default;
$breadcrumb-item-hover-color: rgba(0, 0, 0, 0.7) !default;
$breadcrumb-item-before-color: $breadcrumb-item-color !default;
// scss-docs-end breadcrumb-variables

// Carousel

// scss-docs-start carousel-variables
$carousel-control-prev-icon-bg: none;
$carousel-control-next-icon-bg: none;
$carousel-control-icon-font-size: 1.7rem;
// scss-docs-end carousel-variables

// Scrollspy

// scss-docs-start scrollspy-variables
$scrollspy-menu-sidebar-font-size: 0.8rem !default;
$scrollspy-menu-sidebar-color: #262626 !default;
$scrollspy-menu-sidebar-line-height: 1.1rem !default;
$scrollspy-menu-sidebar-padding-x: 5px !default;
$scrollspy-menu-sidebar-font-weight: 400 !default;
$scrollspy-menu-sidebar-transition: all 0.2s ease-in-out !default;
$scrollspy-menu-sidebar-margin-y: 3px !default;

$scrollspy-menu-sidebar-active-color: $primary !default;
$scrollspy-menu-sidebar-active-font-weight: 600 !default;
$scrollspy-menu-sidebar-active-border-width: 0.125rem !default;
$scrollspy-menu-sidebar-active-border-color: $primary !default;

$scrollspy-collapsible-nav-transition-time: 0.5s !default;
// scss-docs-end scrollspy-variables

// Dividers

// scss-docs-start divider-variables
$divider-height: $border-width-alternate !default;
$divider-width: $border-width-alternate !default;
$divider-bg: $border-color-alternate !default;
$divider-opacity: 1 !default;

$divider-blurry-bg-image: linear-gradient(
  90deg,
  transparent,
  hsl(0, 0%, 40%),
  transparent
) !default;
$divider-blurry-height: 1px !default;
$divider-blurry-opacity: 0.25 !default;

$divider-blurry-vr-bg-image: linear-gradient(
  180deg,
  transparent,
  hsl(0, 0%, 40%),
  transparent
) !default;
$divider-blurry-vr-width: 1px !default;
// scss-docs-end divider-variables
