@use './mixins' as mixin;

// This applies to only primary and secondary color
@mixin responsive-theme($theme) {
  $primary: map-get($theme, primary);
  $secondary: map-get($theme, secondary);

  $background: map-get($theme, background);
  $onBackground: map-get($theme, onBackground);

  $primary-light: color-mix(in oklch, transparent, $primary 70%);
  $secondary-light: color-mix(in oklch, transparent, $secondary 70%);

  $button-primary-gradient: linear-gradient(135deg, transparent 25%, $primary 25%, $primary 50%, transparent 50%);
  $button-secondary-gradient: linear-gradient(135deg, transparent 25%, $secondary 25%, $secondary 50%, transparent 50%);

  .text-primary-light {
    color: $primary-light;
  }

  .border-primary-light {
    border-color: $primary-light;
  }

  .mdb-text {
    color: $onBackground !important;
  }

  .mdb-bg {
    background-color: $background !important;
  }

  .mdb-bg-body {
    background-color: $background !important;
    color: $onBackground !important;
  }

  //shaded-btn
  .shaded-btn {
    &.border-primary::after {
      @include mixin.shaded-bg-builder($primary);
    }

    &.border-secondary::after {
      @include mixin.shaded-bg-builder($secondary);
    }
  }

  //Accordion
  .sub-title-box,
  .media-header {
    color: $onBackground !important;
  }

  .accordion-button {
    padding: 1.15rem;
    color: $primary !important;
  }

  //Money control
  .money-input-control,
  .money-dropdown {
    &:focus {
      border-color: $primary-light;
    }

    &[readonly='true'] {
      border-color: $primary-light;
    }
  }

  //Phone control
  .control-wrapper {
    label {
      color: #48525f;
    }

    .phone-group {
      border: 1px solid #97a2b0;

      .info {
        border-right: 1px solid #97a2b0;
      }
    }

    .extension input {
      border-color: #97a2b0 !important;
    }

    &[inputFocused='true'] {

      .phone-group,
      .phone-group .info,
      .extension input {
        border-color: $primary !important;
      }
    }

    &[readonly='true'] {
      label {
        color: $primary-light;
      }

      .phone-group {
        border-color: $primary-light;

        .info {
          border-right-color: $primary-light;
        }

        .data .country-code,
        .data .phone,
        .data .phone::placeholder {
          color: $primary-light !important;
        }
      }
    }
  }

  //Combo Select
  .sch-combo-select {
    &[readonly='true'] .sch-combo-field-border {
      border-color: $primary-light;
    }
  }

  //Input
  input.sch-input-field,
  .sch-text-area {

    &:focus,
    .has-value {
      border-color: $primary;
    }
  }

  .sch-input {
    &[readonly='true'] {

      .sch-input-control,
      .sch-input-label,
      .text-wrapper {
        border-color: $primary-light;
        color: $primary-light !important;
      }
    }
  }

  .sch-input-control,
  .phone-group,
  .shaded-btn::before {
    @extend .mdb-bg;

    &.focused {
      border-color: $primary;
    }
  }

  input.sch-input-field,
  .sch-text-area {
    color: $onBackground;

    &:not(.form-check-input) {
      @extend .mdb-bg;
    }

    &:not([disabled]):not([ng-reflect-disabled='true'])[readonly] {
      color: $primary-light;
      border-color: $primary-light;

      &::placeholder {
        color: $primary-light !important;
      }
    }

    &.modern,
    &.hybrid {

      &:focus,
      .has-value {
        border-color: $primary;
      }

      &:focus+.sch-input-label,
      &:focus~.sch-input-icon {
        color: $primary;
        border-color: $primary;
      }

      &.has-value+.sch-input-label,
      &.has-placeholder+.sch-input-label {
        border-color: $primary-light;
      }
    }

    &.hybrid {

      &.has-value+.sch-input-label,
      &.has-placeholder+.sch-input-label {
        border-color: #97A2B0;
      }
    }
  }

  .sch-input-label {
    color: $onBackground;

    &:not(.classic) {
      @extend .mdb-bg;
    }

    &[readonly='true'] {
      color: $primary-light;
    }

    &[disabled='true'] {
      color: #48525f;
    }
  }

  .color-picker-wrapper {
    &[readonly=true] {
      border-color: $primary-light;
    }

    &-inner {
      &::-webkit-scrollbar-thumb {
        background: $primary;
      }
    }

    .add-button {
      border: 1px dashed $primary;

      i {
        color: $primary;
      }
    }
  }

  .sch-input-icon {
    @extend .mdb-text;
  }

  //Indeterminate Progress Bar
  .indeterminate-primary {
    background-color: $primary;
  }

  .indeterminate-secondary {
    background-color: $secondary;
  }

  //Auto complete
  .sch-autocomplete-option {
    color: $primary;

    &.selected,
    &:hover {
      background-color: $primary;
      color: white;
    }
  }

  //Combo Select
  .sch-combo-select {
    .add-button {
      background-color: $primary;
    }
  }

  //Spinner
  .loader-primary {
    border-bottom-color: $primary !important;
  }

  .loader-secondary {
    border-bottom-color: $secondary !important;
  }

  // Progress bar
  .percentage-text {
    color: $onBackground;
  }

  .progress-stroke-primary {
    stroke: $primary;
  }

  .progress-stroke-secondary {
    stroke: $secondary;
  }

  //ng Select
  .ng-select {
    &.ng-select-focused {
      &:not(.ng-select-opened)>.ng-select-container {
        border-color: $primary;
      }
    }
  }

  //dropdown menu
  .dropdown-item {
    color: $primary;

    &:hover,
    &.active {
      background-color: $primary;
    }
  }

  //carousel action buttons
  .carousel-control-next .carousel-control-next-icon,
  .carousel-control-prev .carousel-control-prev-icon {
    background-color: $primary;
  }

  //lightbox
  mdb-lightbox .img-count {
    background-color: $primary;
  }

  //gallery
  .overlay .mute-icon {
    background-color: $primary-light;
  }

  //video
  .plyr--full-ui input[type=range] {
    color: $primary;
    background-color: transparent !important;
  }

  .plyr__control--overlaid,
  .plyr--video .plyr__control:focus-visible,
  .plyr--video .plyr__control:hover,
  .plyr--video .plyr__control[aria-expanded='true'] {
    background-color: $primary;
  }

  // //dropdown with dynamic toggle
  .dropdown-row .custom-toggle {
    color: $primary;
  }

  //checkbox and radio theming
  .sch-color-primary {
    & .form-check-input[type='checkbox']:checked {
      background-color: $primary !important;
      border-color: $primary !important;
    }

    & .form-check-input[type='radio']:checked {
      border-color: $primary !important;
    }
  }

  .sch-color-secondary {
    & .form-check-input[type='checkbox']:checked {
      background-color: $secondary !important;
      border-color: $secondary !important;
    }

    & .form-check-input[type='radio']:checked {
      border-color: $secondary !important;
    }
  }

  //star rating component
  .stars {
    .filled path {
      // fill: $primary !important;
      fill: $primary;
    }
  }
}