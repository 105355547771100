$primary: #3b71ca;
$secondary: #9fa6b2;
$success: #14a44d;
$danger: #dc4c64;
$warning: #e4a11b;
$info: #54b4d3;
$light: #fbfbfb;
$dark: #332d2d;
$gray-300: #ccc;

$primary-font: 'Nunito Sans', sans-serif;

$disabled-color: #b9b9b9;

$seech-layout-width: 12.5rem;
$seech-layout-minimize-width: 3.5rem;
$seech-layout-item-space: 0;
$seech-layout-item-icon-right: 1rem;
$seech-layout-item-icon-left: 0;
$seech-layout-item-selected-overlay: $primary;
$seech-layout-padding-top: 0;
$seech-layout-bottom-nav-height: 0;
$seech-layout-header-height: 0;
$seech-layout-mobile-header-height: 0;

//Bootstrap form overrides
$input-disabled-bg: rgba(226, 226, 226, 0.3);
$input-disabled-color: #b9b9b9;
$input-box-shadow: none;
$input-focus-box-shadow: none;
$input-border-radius: 6px;
$form-feedback-icon-valid: none;
$form-feedback-icon-invalid: none;
$input-placeholder-color: #ccd4de;
