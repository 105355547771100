@use '@material/list';
@use '@material/ripple';
@use '@material/list/mixins' as list-mixins;

@include list.deprecated-core-styles;

@mixin seech-side-nav-link-color($color, $primary) {
  @include list-mixins.deprecated-item-graphic-ink-color($color);
  a:not(.sidenav-link):not(.btn):not(.dropdown-item):not(.nav-link):not(
      .navbar-brand
    ):not(.page-link):not(.carousel-control-prev):not(.carousel-control-next) {
    color: $color;
  }
  a:hover {
    // color: rgba($color: $primary, $alpha: 1) !important;
  }

  .mdc-deprecated-list-item {
    @include list-mixins.deprecated-item-primary-text-ink-color($color);
  }
}
//********** Width and Heights***************
.seech-sidenav-container {
  height: 100dvh !important;
}

.layout-header {
  z-index: 2;
  width: 100%;
  @media (min-width: 1024px) {
    z-index: 30;
  }
}

.layout-footer {
  z-index: 2;
  width: 100%;
  height: $seech-layout-bottom-nav-height;
}

.seech-layout,
.seech-nav-footer {
  width: $seech-layout-width;
}
.seech-layout-minimize {
  width: $seech-layout-minimize-width !important;
  .seech-nav-footer {
    width: $seech-layout-minimize-width !important;
  }
}
//********** Paddings ***************

.seech-layout-content {
  padding-top: $seech-layout-mobile-header-height;
  @media (min-width: 1024px) {
    padding-top: $seech-layout-header-height;
  }
}

@media (max-width: 768px) {
  .gold-layout-container {
    padding-bottom: $seech-layout-bottom-nav-height !important;
  }
  .silver-layout-container {
    padding-bottom: $seech-layout-bottom-nav-height !important;
  }
}

//********** Margins ***************
.mdc-deprecated-list {
  .mdc-deprecated-list-item__graphic {
    margin-left: $seech-layout-item-icon-left !important;
  }
  @include list-mixins.deprecated-icon-margin(
    $seech-layout-item-icon-right !important
  );
  .mdc-deprecated-list-item {
    margin-top: $seech-layout-item-space !important;
  }
}

//********** Border Radius ***************
.mdc-deprecated-list {
  .mdc-deprecated-list-item {
    border-radius: 0.25rem;
  }
}

//********** Colors ***************
.mdc-deprecated-list {
  .mdc-deprecated-list-item {
    .mdc-deprecated-list-item__ripple {
      @include ripple.states($seech-layout-item-selected-overlay, true);
    }
  }

  .seech-navbar-link-active-primary {
    @include list-mixins.deprecated-item-primary-text-ink-color($primary);
    @include list-mixins.deprecated-item-graphic-ink-color($primary);
  }

  .seech-navbar-link-active-light {
    @include list-mixins.deprecated-item-primary-text-ink-color(
      $light !important
    );
    @include list-mixins.deprecated-item-graphic-ink-color($light !important);
  }

  .seech-navbar-link-active-dark {
    @include list-mixins.deprecated-item-primary-text-ink-color(
      $dark !important
    );
    @include list-mixins.deprecated-item-graphic-ink-color($dark !important);
  }
}

//********** Light/Dark Theme Color ***************
[data-theme^='dark-'],
.dark {
  .mdc-deprecated-list {
    @include seech-side-nav-link-color($light, $primary);
  }
}

.light,
[data-theme^='light-'] {
  .mdc-deprecated-list {
    @include seech-side-nav-link-color($dark, $primary);
  }
}


html {
  --mdc-typography-font-family: $primary-font;
}
